import { General } from '../types/general'
import { store } from '../store/configureStore'
import { setProgressBar } from '../store/ProgressBarSlice'

const xsrfToken = document.cookie
  .split('; ')
  .find(row => row.startsWith('XSRF-TOKEN='))
  ?.split('=')[1] ?? ''
const headers: HeadersInit = {
  'X-XSRF-TOKEN': xsrfToken
}

const convertFormToJson = (form: HTMLFormElement) => {
  let formDataJson: any = {}
  const formData = new FormData(form)
  Array.from(formData.entries()).forEach(([key, value]) => {
    const node: any = form.querySelector(`[name=${key}]`)
    formDataJson[key] = node.files ?? value
    //TODO: other list types
  })
  return formDataJson
}

const submitForm = async (url: string, body: any | FormData) => {
  // let total = 0
  const method = 'POST'
  headers['Accept'] = 'application/json, text/plain'
  if (!(body instanceof FormData)) {
    headers['Content-Type'] = 'application/json;charset=UTF-8'
    body = JSON.stringify(body)
    // total = body.length
  } else {
    // total = Array
    //   .from(body.entries(), ([key, prop]) => ({
    //     ['ContentLength']:
    //       typeof prop === 'string'
    //         ? prop.length
    //         : prop.size
    //   }))
    //   .reduce((accumulator, currentValue) => accumulator + currentValue.ContentLength, total)
  }
  const onUploadProgress = (event: ProgressEvent) => {
    const percentage = event.loaded / event.total * 100
    store.dispatch(setProgressBar({
      visible: true,
      percentage: percentage <= 100
        ? percentage
        : 100
    }))
  }
  const response = await xhrFetch(url, { method, body, headers }, onUploadProgress)
  /** Fetch has no way of checking upload progress (currently) */
  // const response = await fetch(url, { method, body, headers })
  return await response.json() as General
}

const xhrFetch = (url: string, options: RequestInit = {}, onUploadProgress: ((this: XMLHttpRequestUpload, ev: ProgressEvent<EventTarget>) => any)) =>
  new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    if (xhr.upload && onUploadProgress)
      xhr.upload.addEventListener('progress', onUploadProgress)
    xhr.open(options.method || 'get', url)
    for (const [key, value] of Object.entries(options.headers || {}))
      xhr.setRequestHeader(key, value)
    xhr.addEventListener('load', event =>
      resolve(new Response(xhr.responseText)))
    xhr.addEventListener('loadend', event =>
      store.dispatch(setProgressBar({ visible: false, percentage: 0 })))
    xhr.addEventListener('error', reject)
    xhr.send(options.body as XMLHttpRequestBodyInit)
  })


export {
  convertFormToJson,
  submitForm
}