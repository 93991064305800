import './Spinner.css'
//React
import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { ProgressBarState } from '../../store/ProgressBarSlice'
import { useAppSelector } from '../../store/hooks'
// import BootstrapSpinner from "react-bootstrap/Spinner"
import BackgroundCanvas from '../../layout/BackgroundCanvas'

const Spinner = ({ }) => {
	const state: ProgressBarState = useAppSelector(selector => selector.progressBar)
	// return (
	// 	<BootstrapSpinner animation="border" variant="warning" />
	// )
	return (
		<>
			<BackgroundCanvas enabled={true} />
			<div className="d-flex justify-content-center align-items-center py-5 my-5 position-relative">
				<div className="centered-logo">
					<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
						x="0px" y="0px" viewBox="0 0 163.4 93.6" xmlSpace="preserve">
						<g>
							<g>
								<rect x="84" y="30.4" width="4.2" height="22.6" />
								<polygon points="98.7,30.4 89.2,41.7 98.7,53 104.3,53 94.6,41.7 104.3,30.4 		" />
								<path d="M151.2,39.7c-3.5-1.1-4.5-1.6-4.5-3.2c0-0.8,0.3-1.1,0.6-1.4c1.7-1.4,6.7-0.9,9.6-0.2l0.8-4c-0.9-0.2-9-2.1-13,1
			c-1.4,1.1-2.1,2.7-2.1,4.6c0,4.8,4.3,6.2,7.4,7.1c3.4,1.1,4.5,1.6,4.5,3.2c0,0.8-0.3,1.1-0.6,1.4c-1.8,1.4-6.9,1.2-9.9,0.5l-0.8,4
			c0.5,0.1,3,0.6,5.8,0.6c2.6,0,5.6-0.4,7.5-2c1.4-1.1,2.1-2.7,2.1-4.5C158.5,42,154.3,40.6,151.2,39.7" />
								<polygon
									points="110.4,40.3 110.4,34.6 121.2,34.6 123,30.4 106.2,30.4 106.2,53 110.4,53 110.4,44.4 117,44.4 118.7,40.3 		" />
								<polygon points="127.1,30.4 117.7,53 122.4,53 124.3,48.1 130.2,48.1 131.9,44 125.8,44 129.6,34.3 136.8,53 141.4,53 132.1,30.4 
					" />
								<path className="sail" fill="#E3E3E3" d="M65.2,62.1c-0.4-0.5-0.8-1-1.2-1.5c0,0,0,0,0,0c-12.3-14.6-28.9-24-49.5-28.7c-1.5,1-3,2.1-4.5,3.2
			C31.1,39,54.7,50.8,66.7,64c0,0,0,0,0,0c-0.4-0.6-0.8-1.1-1.3-1.6C65.3,62.3,65.3,62.2,65.2,62.1" />
								<path className="sail" fill="#F16E24" d="M52.6,14.5C52.6,14.5,52.6,14.5,52.6,14.5C52.6,14.5,52.6,14.5,52.6,14.5 M52.6,14.5
			C52.6,14.5,52.6,14.5,52.6,14.5C52.6,14.5,52.6,14.5,52.6,14.5 M106.9,8.8C99.3,7.5,91.7,6.7,84,6.7C57,6.7,30.1,15.6,6.5,30.4
			c26,4.2,46.3,15.2,60.2,33.7c3.3-11.5,7.5-21.4,12.7-29.7L53.3,44.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.5-0.1-0.1,0,0
			c0,0,0-0.1-0.1-0.2l22.7-12.8L17,29.9v-0.2l58.7-1.7L52.5,14.6c0,0,0-0.1,0-0.1c-0.1,0.1-0.1,0.3,0.1-0.1c0-0.1,0-0.1,0,0L78,25
			l-6.2-13.9c0.1-0.1,0.1-0.1,0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1L82,24.5L83.9,10
			H84h0.1h0.1l2,15.1C92.1,18,99.1,12.6,106.9,8.8" />
							</g>
							<g>
								<polygon points="9.1,81.9 7.8,83.6 7.1,84.6 7,84.6 7.1,83.6 7.1,81.9 6.5,81.9 6.5,87 7.1,87 7.1,85.3 7.7,84.5 9.2,87 9.9,87 
			8.1,84.1 9.8,81.9 		" />
								<path d="M12.6,86.4L12.6,86.4c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
			c-0.2-0.2-0.3-0.6-0.3-1.1v-2.5H11v2.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
			c0.2-0.1,0.2-0.3,0.2-0.6v-2.6h0.6V87h-0.6V86.4z" />
								<polygon points="18.1,83.2 17.7,84.9 17.4,86.5 17.3,86.5 17,84.9 16.6,83.2 16,83.2 15.6,84.9 15.2,86.5 15.1,86.5 14.8,84.9 
			14.4,83.2 13.9,83.2 14.8,87 15.5,87 16,85.2 16.2,83.8 16.3,83.8 16.6,85.2 17,87 17.7,87 18.6,83.2 		" />
								<path d="M20.2,86.6c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3v-0.6h-0.7
			c-0.3,0-0.5,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4V86c0,0.2,0.1,0.3,0.2,0.4C19.9,86.5,20,86.6,20.2,86.6 M21.6,87
			c-0.4,0-0.5-0.2-0.5-0.5v-0.1H21c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.6-0.1-0.8-0.3
			C19.1,86.6,19,86.3,19,86c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.6-0.3,1.1-0.3h0.7v-0.4c0-0.3-0.1-0.5-0.2-0.6
			c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-0.3-0.3c0.1-0.2,0.3-0.4,0.5-0.5
			c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.8,0.1,1,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.1H22V87H21.6z" />
								<path d="M22.8,83.2h0.6V87h-0.6V83.2z M23,82.3c-0.2,0-0.3-0.1-0.3-0.3v-0.1c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3V82
			C23.4,82.2,23.3,82.3,23,82.3" />
								<path d="M25.2,87c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.7H24v-0.5h0.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.1,0.1-0.3
			v-0.7h0.5v1h0.7v0.5h-0.7v2.8h0.7V87H25.2z" />
								<polygon
									points="28.3,81.9 28.3,87 28.9,87 28.9,84.7 30.9,84.7 30.9,84.1 28.9,84.1 28.9,82.4 31,82.4 31,81.9 		" />
								<path d="M33,86.6c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.5,0.2-0.8v-0.8c0-0.4-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8C32.5,86.5,32.7,86.6,33,86.6 M33,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
			C33.4,87,33.2,87.1,33,87.1" />
								<path d="M37.5,86.4L37.5,86.4c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
			c-0.2-0.2-0.3-0.6-0.3-1.1v-2.5h0.6v2.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
			c0.2-0.1,0.2-0.3,0.2-0.6v-2.6H38V87h-0.6V86.4z" />
								<path d="M39.1,87v-3.8h0.6v0.6h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3
			c0.2,0.2,0.3,0.6,0.3,1.1V87h-0.6v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6V87H39.1z" />
								<path d="M44.1,86.6c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3v-1.5
			c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.5,0.1-0.7,0.3
			c-0.2,0.2-0.2,0.5-0.2,0.8v0.8c0,0.3,0.1,0.6,0.2,0.8C43.6,86.5,43.8,86.6,44.1,86.6 M45,86.4L45,86.4c-0.1,0.2-0.3,0.4-0.4,0.5
			c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.8
			c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0.1,0.6,0.2
			c0.2,0.1,0.3,0.3,0.4,0.5H45v-2.2h0.6V87H45V86.4z" />
								<path d="M47.6,86.6c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3v-0.6h-0.7
			c-0.3,0-0.5,0-0.6,0.1C47,85.5,47,85.6,47,85.8V86c0,0.2,0.1,0.3,0.2,0.4C47.2,86.5,47.4,86.6,47.6,86.6 M49,87
			c-0.4,0-0.5-0.2-0.5-0.5v-0.1h-0.1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.6-0.1-0.8-0.3
			c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.6-0.3,1.1-0.3h0.7v-0.4c0-0.3-0.1-0.5-0.2-0.6
			c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-0.3-0.3c0.1-0.2,0.3-0.4,0.5-0.5
			c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.8,0.1,1,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.1h0.4V87H49z" />
								<path d="M50.9,87c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.7h-0.6v-0.5h0.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.1,0.1-0.3
			v-0.7h0.5v1h0.7v0.5h-0.7v2.8h0.7V87H50.9z" />
								<path d="M52.5,83.2H53V87h-0.6V83.2z M52.7,82.3c-0.2,0-0.3-0.1-0.3-0.3v-0.1c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3V82
			C53.1,82.2,53,82.3,52.7,82.3" />
								<path d="M55.4,86.6c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.5,0.2-0.8v-0.8c0-0.4-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8C54.9,86.5,55.1,86.6,55.4,86.6 M55.4,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4C54.2,86.5,54,86.2,54,86c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
			C55.8,87,55.6,87.1,55.4,87.1" />
								<path d="M57.8,87v-3.8h0.6v0.6h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3
			c0.2,0.2,0.3,0.6,0.3,1.1V87h-0.6v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6V87H57.8z" />
								<path d="M63.2,83.7h-0.5v-0.5h0.5v-0.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2h0.6v0.5h-0.7v1.1h0.7v0.5h-0.7V87h-0.6
			V83.7z" />
								<path d="M66.4,86.6c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.5,0.2-0.8v-0.8c0-0.4-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8C65.9,86.5,66.1,86.6,66.4,86.6 M66.4,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
			C66.8,87,66.6,87.1,66.4,87.1" />
								<path d="M68.8,87v-3.8h0.6v0.7h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2h0.2v0.6h-0.4c-0.3,0-0.5,0.1-0.6,0.2
			c-0.1,0.1-0.2,0.3-0.2,0.5V87H68.8z" />
								<path d="M73.5,87c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.7h-0.6v-0.5h0.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.1,0.1-0.3
			v-0.7h0.5v1h0.7v0.5h-0.7v2.8h0.7V87H73.5z" />
								<path d="M75,81.6h0.6v2.2h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3s0.3,0.6,0.3,1.1V87h-0.6
			v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6V87H75V81.6z" />
								<path d="M80,83.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.1h1.8v-0.1C80.9,84,80.6,83.6,80,83.6 M80,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9v0.1h-2.4v0.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C80.7,87,80.4,87.1,80,87.1" />
								<path
									d="M85.2,83l0.2-0.6h0.1l0.2,0.6l0.6,1.9h-1.6L85.2,83z M85.1,81.9L83.5,87h0.6l0.5-1.5h1.8l0.5,1.5h0.6l-1.6-5.1H85.1z" />
								<path d="M89.4,86.6c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3v-1.5
			c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.5,0.1-0.7,0.3
			c-0.2,0.2-0.2,0.5-0.2,0.8v0.8c0,0.3,0.1,0.6,0.2,0.8C88.8,86.5,89.1,86.6,89.4,86.6 M90.2,86.4L90.2,86.4
			c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.4-0.3-0.6
			c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1
			c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5h0.1v-2.2h0.6V87h-0.6V86.4z" />
								<polygon
									points="93.9,83.2 93.3,85.1 93,86.5 92.9,86.5 92.6,85.1 92,83.2 91.4,83.2 92.6,87 93.3,87 94.5,83.2 		" />
								<path d="M96,86.6c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3v-0.6h-0.7
			c-0.3,0-0.5,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4V86c0,0.2,0.1,0.3,0.2,0.4C95.6,86.5,95.8,86.6,96,86.6 M97.4,87
			c-0.4,0-0.5-0.2-0.5-0.5v-0.1h-0.1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.6-0.1-0.8-0.3
			c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.6-0.3,1.1-0.3h0.7v-0.4c0-0.3-0.1-0.5-0.2-0.6
			c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-0.3-0.3c0.1-0.2,0.3-0.4,0.5-0.5
			c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.8,0.1,1,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.1h0.4V87H97.4z" />
								<path d="M98.5,87v-3.8h0.6v0.6h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3
			c0.2,0.2,0.3,0.6,0.3,1.1V87h-0.6v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6V87H98.5z" />
								<path d="M103.5,87.1c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.8-0.4-1.5c0-0.7,0.1-1.2,0.4-1.5c0.3-0.3,0.6-0.5,1.1-0.5
			c0.4,0,0.6,0.1,0.8,0.2c0.2,0.2,0.4,0.4,0.5,0.6l-0.5,0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.6-0.2
			c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
			c0.1-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C104.1,87,103.8,87.1,103.5,87.1" />
								<path d="M106.8,83.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.1h1.8v-0.1C107.7,84,107.4,83.6,106.8,83.6 M106.8,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9v0.1h-2.4v0.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C107.4,87,107.1,87.1,106.8,87.1" />
								<path d="M109.1,87v-3.8h0.6v0.6h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
			c0.2,0.1,0.3,0.3,0.4,0.5h0.1c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.6,0.3,1.1V87
			h-0.6v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.6V87h-0.6v-2.4
			c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.6V87H109.1z" />
								<path d="M116.1,83.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.1h1.8v-0.1C117,84,116.7,83.6,116.1,83.6 M116.1,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9v0.1h-2.4v0.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C116.8,87,116.5,87.1,116.1,87.1" />
								<path d="M118.4,87v-3.8h0.6v0.6h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3
			c0.2,0.2,0.3,0.6,0.3,1.1V87h-0.6v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6V87H118.4z" />
								<path d="M122.9,87c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.7h-0.6v-0.5h0.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.1,0.1-0.3
			v-0.7h0.5v1h0.7v0.5h-0.7v2.8h0.7V87H122.9z" />
								<path d="M127.3,86.6c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.5,0.2-0.8v-0.8c0-0.4-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8C126.7,86.5,127,86.6,127.3,86.6 M127.3,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
			C127.7,87,127.5,87.1,127.3,87.1" />
								<path d="M129.8,83.7h-0.5v-0.5h0.5v-0.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2h0.6v0.5h-0.7v1.1h0.7v0.5h-0.7V87h-0.6
			V83.7z" />
								<path d="M134.6,87.1c-0.4,0-0.7-0.1-1-0.2c-0.3-0.2-0.5-0.4-0.7-0.6l0.4-0.4c0.2,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.7,0.2
			c0.7,0,1-0.3,1-1c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.7-0.3l-0.4-0.1c-0.4-0.1-0.8-0.3-1-0.5c-0.2-0.2-0.3-0.5-0.3-0.9
			c0-0.5,0.1-0.8,0.4-1c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.5,0.3,0.6,0.6l-0.4,0.3c-0.1-0.2-0.3-0.4-0.4-0.5
			c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.2,0.4-0.2,0.7c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.6,0.3
			l0.4,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2,0.1,0.3,0.1,0.6c0,0.5-0.1,0.8-0.4,1.1
			C135.5,86.9,135.1,87.1,134.6,87.1" />
								<path d="M138.3,87.1c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.8-0.4-1.5c0-0.7,0.1-1.2,0.4-1.5c0.3-0.3,0.6-0.5,1.1-0.5
			c0.4,0,0.6,0.1,0.8,0.2c0.2,0.2,0.4,0.4,0.5,0.6l-0.5,0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.6-0.2
			c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
			c0.1-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C139,87,138.7,87.1,138.3,87.1" />
								<path d="M140.4,83.2h0.6V87h-0.6V83.2z M140.7,82.3c-0.2,0-0.3-0.1-0.3-0.3v-0.1c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3V82
			C141,82.2,140.9,82.3,140.7,82.3" />
								<path d="M143.3,83.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.1h1.8v-0.1C144.2,84,143.9,83.6,143.3,83.6 M143.3,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9v0.1h-2.4v0.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C144,87,143.7,87.1,143.3,87.1" />
								<path d="M145.7,87v-3.8h0.6v0.6h0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3
			c0.2,0.2,0.3,0.6,0.3,1.1V87h-0.6v-2.4c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6V87H145.7z" />
								<path d="M150.6,87.1c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.8-0.4-1.5c0-0.7,0.1-1.2,0.4-1.5c0.3-0.3,0.6-0.5,1.1-0.5
			c0.4,0,0.6,0.1,0.8,0.2c0.2,0.2,0.4,0.4,0.5,0.6l-0.5,0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.6-0.2
			c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.5-0.2,0.8v0.8c0,0.4,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
			c0.1-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C151.2,87,151,87.1,150.6,87.1" />
								<path d="M153.9,83.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.2,0.8v0.1h1.8v-0.1C154.8,84,154.5,83.6,153.9,83.6 M153.9,87.1
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.6,0.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.3
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9v0.1H153v0.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3
			c0.3,0,0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5l0.4,0.3c-0.1,0.3-0.3,0.5-0.5,0.6C154.5,87,154.3,87.1,153.9,87.1" />
								<path d="M157.3,87.1c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5l0.4-0.3c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.3,0.1,0.6,0.1
			c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.5-0.2l-0.3-0.1c-0.4-0.1-0.6-0.2-0.8-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3-0.1,0.5-0.1
			c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.5,0.4l-0.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.4,0-0.7,0.2-0.7,0.6c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.5,0.2l0.3,0.1c0.4,0.1,0.7,0.2,0.8,0.4c0.2,0.2,0.2,0.4,0.2,0.7
			c0,0.4-0.1,0.7-0.3,0.9C158,87,157.7,87.1,157.3,87.1" />
								<path
									d="M121.5,69.2h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6C121,69,121.2,69.2,121.5,69.2" />
								<path d="M119.9,69.2h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6
			C119.4,69,119.6,69.2,119.9,69.2" />
								<path d="M121.1,72.9c0,0.5-0.3,0.7-1.1,0.7c-0.6,0-0.8-0.2-0.8-0.6c0-0.4,0.3-0.7,1-1l0.9-0.5V72.9z M146.6,72l-0.9,0.1l0.1,0.5
			c0.1,0.4,0.1,0.7,0.1,0.9c0,0.5-0.2,0.7-0.6,0.7h-0.2c-0.4,0-0.6-0.3-0.6-0.9v-0.7h-0.9v0.7c0,0.7-0.3,0.9-0.9,0.9
			c-0.4,0-0.8-0.1-1.2-0.3l-0.1,0L142,73l-0.8-0.4l-0.4,0.8c-0.3,0.5-0.5,0.7-1.1,0.7h-0.2h0h-0.5c-0.5,0-0.8-0.3-0.8-0.8v-1.1h-0.9
			v1.1c0,0.7-0.2,0.9-0.6,0.9h-0.2c-0.5,0-0.6-0.3-0.6-0.9v-0.7h-0.9v0.7c0,0.7-0.3,0.9-0.9,0.9c-0.4,0-0.7-0.1-1.1-0.3l-0.1,0
			l0.5-0.9l-0.8-0.4l-0.4,0.8c-0.3,0.5-0.5,0.7-1.1,0.7h-8.5c-0.5,0-0.8-0.2-0.8-0.6v-3.5h-0.9v0.5l-1.1,0.6
			c-1.2,0.6-1.7,1.2-1.7,2.1c0,0.9,0.5,1.4,1.4,1.4c0.7,0,1.1-0.3,1.3-1.1h0.1v0.1c0,1.1,0.5,1.6,1.7,1.6h7.9c0.8,0,1.3-0.1,1.7-0.5
			l0.3,0.2c0.6,0.3,0.9,0.5,1.3,0.5c0.6,0,1-0.4,1.1-1.2h0.1c0.1,0.8,0.5,1.2,1.2,1.2c0.6,0,1-0.3,1.1-1.2h0.1
			c0.2,0.7,0.7,1.1,1.6,1.1h0.1c0.8,0,1.3-0.1,1.7-0.5l0.3,0.2c0.6,0.3,0.9,0.5,1.3,0.5c0.6,0,1-0.4,1.1-1.2h0.1
			c0.1,0.8,0.6,1.2,1.5,1.2c1,0,1.5-0.6,1.5-1.8c0-0.3,0-0.7-0.1-1.1L146.6,72z" />
								<path d="M150.7,69.7H150c-0.5,0-0.7-0.1-0.7-0.4c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.2l0.4-0.5c-0.2-0.3-0.6-0.4-0.9-0.4
			c-0.6,0-1,0.4-1,0.9c0,0.2,0.1,0.4,0.2,0.5l0,0.1h-0.4v0.7h2.2V69.7z" />
								<path d="M157.8,74.3l-2.1-0.6l0.2-0.4c0.2-0.5,0.5-0.8,0.9-0.8c0.6,0,1,0.6,1,1.5V74.3z M150.7,74.2h-1.2c-0.6,0-0.9-0.2-0.9-0.7
			c0-0.6,0.4-1,1-1c0.7,0,1.2,0.6,1.2,1.5V74.2z M156.9,71.3c-0.9,0-1.4,0.6-1.7,1.4l-0.3,0.7c-0.2,0.5-0.4,0.7-0.9,0.7H152h0h-0.5
			c0-1.8-0.7-2.9-1.9-2.9c-1.1,0-1.9,1-1.9,2.3c0,1.1,0.6,1.7,1.7,1.7h1.2c-0.3,0.8-1,1.3-2.4,1.3h-0.6v1.1h0.6
			c1.8,0,2.9-0.9,3.2-2.4h0.3h0h2.1c0.7,0,1.1-0.2,1.4-0.6l2.7,0.8l0.8-1.1V74C158.6,72.3,158,71.3,156.9,71.3" />
								<path
									d="M10.4,78.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6c0,0.4,0.2,0.6,0.5,0.6h0.2c0.3,0,0.5-0.2,0.5-0.6C10.9,78.8,10.7,78.6,10.4,78.6" />
								<path
									d="M8.8,78.6H8.6c-0.3,0-0.5,0.2-0.5,0.6c0,0.4,0.2,0.6,0.5,0.6h0.2c0.3,0,0.5-0.2,0.5-0.6C9.3,78.8,9.1,78.6,8.8,78.6" />
								<path d="M33.4,74L32,72.3v0c0.4-0.1,0.8-0.2,1.2-0.2c0.9,0,1.3,0.2,1.3,0.6c0,0.4-0.3,0.5-0.8,1L33.4,74z M15.6,74.2
			c-0.6,0-0.9-0.2-0.9-0.6c0-0.4,0.4-0.6,0.9-0.9l0.6-0.3l0.5,1.4c0.1,0.1,0.1,0.3,0.1,0.4H15.6z M36.9,73.4c0,0.5-0.2,0.8-0.8,0.8
			h-1h0h-0.8l0,0c0.8-0.6,1.1-0.9,1.1-1.7c0-1-0.7-1.5-2.1-1.5c-0.7,0-1.4,0.2-2,0.5v1.2l1.2,1.5l0,0h-1.9h0h-8.7
			c-0.5,0-0.8-0.3-0.8-0.8V68h-0.9v5.4c0,0.5-0.3,0.8-0.8,0.8h-0.8c-0.5,0-0.6-0.2-0.8-0.7l-0.8-2h-1l-0.6,0.3
			c-0.9,0.6-1.4,1.2-1.4,2c0,0.1,0,0.2,0,0.4c-0.2,0.1-0.4,0.1-0.8,0.1c-0.4,0-1-0.1-1.7-0.2l-1.3-0.2V75l2.3,0.3
			c-0.2,0.8-1.1,1.2-2.4,1.2H9.2c-1.3,0-2.1-0.6-2.1-1.6c0-0.5,0.2-1.1,0.4-1.6l-0.8-0.2c-0.3,0.7-0.5,1.5-0.5,2.1
			c0,1.6,1.2,2.6,3.2,2.6c2.1,0,3.4-0.9,3.6-2.5c0.4-0.1,0.6-0.4,0.8-0.8H14c0.3,0.6,0.8,0.9,1.7,0.9H17v-0.7l0.1,0
			c0.3,0.5,0.7,0.7,1.4,0.7h0.2h-0.2h0h0.2c0.9,0,1.3-0.3,1.4-1.1h0.1c0.2,0.8,0.7,1.1,1.7,1.1h8.6h0h1c0.6,0,1.2-0.2,1.7-0.5
			c0.5,0.3,1.2,0.5,1.8,0.5h1c1.2,0,1.7-0.6,1.7-1.9V68h-0.9V73.4z" />
								<rect x="39.2" y="68" width="0.9" height="7.4" />
								<path d="M45.7,71c-0.8,0-1.6,0.5-2,1.5l0.8,0.5c0.3-0.5,0.6-0.8,1.2-0.8c0.7,0,1.2,0.6,1.2,1.6h-2.2c-1.3,0-1.9,0.7-1.9,2.1v2.5
			h0.9v-2.5c0-0.7,0.3-1,1-1h3v-1.1C47.8,72,47,71,45.7,71" />
								<path
									d="M64,69.4h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6H64c-0.3,0-0.5,0.2-0.5,0.6C63.5,69.3,63.7,69.4,64,69.4" />
								<path
									d="M65.6,69.4h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6C65.1,69.3,65.3,69.4,65.6,69.4" />
								<path
									d="M70.3,70.9h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6C69.8,70.7,70,70.9,70.3,70.9" />
								<path
									d="M68.7,70.9h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6C68.2,70.7,68.4,70.9,68.7,70.9" />
								<path d="M64.9,73.9c-0.7-0.4-1.1-0.8-1.1-1.5s0.4-1.1,1-1.1h0.1c0.6,0,1,0.4,1,1.1S65.5,73.6,64.9,73.9 M70.8,74.2
			c-0.5,0-0.8-0.3-0.8-0.8v-1.5h-0.9v1.5c0,0.5-0.3,0.8-0.8,0.8h-1.1h-1.3l0-0.1c0.6-0.5,0.9-1.1,0.9-1.8c0-1.1-0.8-2.1-1.9-2.1
			c-1.1,0-1.9,0.9-1.9,2.1c0,0.7,0.3,1.3,0.9,1.8l0,0.1h-1.3h0h-8.7c-0.5,0-0.7-0.3-1.1-1.2l-1-2.9l-1,0.3l0.9,2.6
			c0.1,0.4,0.2,0.6,0.2,0.7c0,0.3-0.2,0.4-0.9,0.4h-1.4v-0.7h-0.9v0.7c0,0.8,0.4,1.1,1.1,1.1h0.9c0.9,0,1.2-0.3,1.4-1.1l0.1,0
			c0.4,0.8,0.8,1.1,1.7,1.1l0,0v0h9c0.7,0,1.4-0.2,1.9-0.5c0.6,0.4,1.2,0.5,2,0.5h0.9c0.9,0,1.3-0.3,1.5-1.1h0.1
			c0.2,0.8,0.7,1.1,1.7,1.1l0,0l0.7,0c0.9,0,1.3-0.3,1.4-1.1h0.1c0.2,0.8,0.7,1.1,1.7,1.1h0.6c1.2,0,1.7-0.6,1.7-1.9V68h-0.9v5.4
			c0,0.5-0.2,0.8-0.8,0.8h-0.7c-0.5,0-0.8-0.3-0.8-0.8V68H73v5.4c0,0.5-0.3,0.8-0.8,0.8H70.8z" />
								<path
									d="M85.5,71.4h0.2c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6C85,71.2,85.2,71.4,85.5,71.4" />
								<path
									d="M83.9,71.4H84c0.3,0,0.5-0.2,0.5-0.6c0-0.4-0.2-0.6-0.5-0.6h-0.2c-0.3,0-0.5,0.2-0.5,0.6C83.4,71.2,83.6,71.4,83.9,71.4" />
								<path
									d="M91.6,76.3h-0.2c-0.3,0-0.5,0.2-0.5,0.6c0,0.4,0.2,0.6,0.5,0.6h0.2c0.3,0,0.5-0.2,0.5-0.6C92.1,76.4,91.9,76.3,91.6,76.3" />
								<path
									d="M90,76.3h-0.2c-0.3,0-0.5,0.2-0.5,0.6c0,0.4,0.2,0.6,0.5,0.6H90c0.3,0,0.5-0.2,0.5-0.6C90.5,76.4,90.3,76.3,90,76.3" />
								<path d="M96.7,74.2h-1.2c-0.6,0-0.9-0.2-0.9-0.7c0-0.6,0.4-1,1-1c0.7,0,1.2,0.6,1.2,1.5V74.2z M112.2,73.4c0,0.5-0.2,0.8-0.8,0.8
			h-0.6c-0.4,0-0.6,0-0.9-0.2v-0.2c0-1.7-0.7-2.4-2.3-2.4h-8.4v-0.1l4.3-2.2v-1.2l-4.7,2.4v2.1h8.9c1,0,1.5,0.4,1.5,1.4v0.4H98h0
			h-0.5c0-1.8-0.7-2.9-1.9-2.9c-1.1,0-1.9,1-1.9,2.3c0,1.1,0.6,1.7,1.7,1.7h1.2c-0.3,0.8-1,1.3-2.4,1.3h-0.6v1.1h0.6
			c1.8,0,2.9-0.9,3.2-2.4h0.3h0h11.4l0.3-0.5c0.4,0.4,0.8,0.5,1.3,0.5h0.6c1.2,0,1.7-0.6,1.7-1.9V68h-0.9V73.4z" />
								<rect x="114.6" y="68" width="0.9" height="7.4" />
								<path
									d="M83,74.2c-1.7,0-2.4-0.3-2.4-1.2c0-0.4,0.1-0.8,0.4-1.4l-0.8-0.2c-0.3,0.7-0.4,1.3-0.4,1.8c0,1.6,1,2.2,3.8,2.2h1.7
			c2.3,0,3-0.3,3.2-1.3h0.1c0.2,0.8,0.7,1.2,1.7,1.2h0.5c1.4,0,2.1-0.6,2.1-1.9c0-0.3,0-0.7-0.1-1.3l-0.1-0.9l-0.9,0.1l0.2,0.9
			c0.1,0.6,0.1,0.9,0.1,1.1c0,0.6-0.3,0.7-1.2,0.7h-0.5c-0.6,0-0.8-0.3-0.8-0.8v-1.5h-0.9v1.5c0,0.7-0.5,0.8-2.4,0.8H83z" />
							</g>
						</g>
					</svg>
					{state.visible && <ProgressBar className="mt-1" now={state.percentage} style={{
						width: 256,
						height: 4,
					}} />}
				</div>
			</div>
		</>
	)
}

export default Spinner