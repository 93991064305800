import './DashboardCard.css'
import * as React from 'react'
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom'

//props
type DashboardCardProps = {
  title: string
  target: string
  children: React.ReactNode
}

const DashboardCard = ({ title, target, children }: DashboardCardProps) => {
  const navigate = useNavigate()
  return (
    <Card
      className="admin-card shadow my-3"
      onClick={() => navigate(target)}
    >
      <Card.Header className="bg-transparent text-primary">
        {children}
      </Card.Header>
      <Card.Body>
        <Card.Title className="d-flex justify-content-center align-items-center">
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  )
}

export default DashboardCard