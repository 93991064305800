//React
import * as React from "react";
import { useState } from "react";
//Bootstrap
import Group from "react-bootstrap/FormGroup";
import Label from "react-bootstrap/FormLabel";
import Check from "react-bootstrap/FormCheck";
//Props
import { RatingScaleProps } from "../Props";
const RatingScale = ({ question, answer, scale, id }: RatingScaleProps) => {
  const [checkedPoint, setCheckedPoint] = useState("");
  const handleClick = (event: React.MouseEvent) => {
    event.currentTarget.children[1].children[0].setAttribute("checked", "true");
  };
  return (
    <Group className={`py-3 border-bottom`}>
      <Label className="fs-4">
        Q{id + 1}) {question}
      </Label>
      <div className="d-flex my-2">
        {scale.points.map((point) => {
          return (
            <div
              onClick={handleClick}
              data-id=""
              key={`option-${point.value}-${id}`}
              className="me-4 d-flex flex-column justify-content-center align-items-center"
            >
              <Label>{point.text}</Label>
              <Check
                type="radio"
                name={`group-${id}`}
                value={point.value}
                // checked={true}
              />
            </div>
          );
        })}
      </div>
    </Group>
  );
};

export default RatingScale;
