//React
import * as React from "react";
import { useParams } from "react-router";
//Question Components
import ShortAnswer from "../components/Feedback/Questions/ShortAnswer";
import RatingScale from "../components/Feedback/Questions/RatingScale";
//Data
import { questions } from "../components/Feedback/Questions/Data";

const Feedback: React.FC = () => {
  const { id } = useParams();

  return (
    <div className="p-2 row">
      <h1 className="display-6 mb-4 text-primary">Feedback</h1>
      {questions.map((question, i) => {
        switch (question.questionType) {
          case "shortAnswer":
            return (
              <ShortAnswer
                key={`question-${i}`}
                question={question.question}
                answer={question.answer}
                id={i}
              />
            );
          case "scale":
            return (
              <RatingScale
                id={i}
                question={question.question}
                scale={question.scale!}
                answer={question.answer}
                key={`question-${i}`}
              />
            );
          default:
            break;
        }
      })}
    </div>
  );
};

export default Feedback;
