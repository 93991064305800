import './Organization.css'
import * as React from 'react'
import { useState, useEffect, createRef } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Select from 'react-bootstrap/FormSelect'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from '../Spinner/Spinner'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import Generic from '../Generic/Generic';
import { submitForm } from '../../utils/forms'
import {
  OrganizationProfile,
  OrganizationRepresentatives,
} from '../../types/profile'
import { useNavigate, useParams } from 'react-router'
import Toolbar from '../Toolbar/Toolbar'
import { Download } from 'react-bootstrap-icons'
import { downloadCSVFile, escapeData } from '../../utils/csv'
import { Toast, ToastContainer } from 'react-bootstrap'
import { Applicant } from '../../types/applicant'
import { SECTOR_TYPES, INDUSTRY_TYPES, ORGANIZATION_NAMES, DOWNLOAD, ORGANIZATION_FILE, ORGANIZATION_REGISTRATION, GET_APPROVALS } from '../../API'

interface OrganizationProps {
  title?: string
  isEditing?: boolean
  profile?: OrganizationProfile
  representatives?: OrganizationRepresentatives
}

const Organization: React.FC<OrganizationProps> = ({ isEditing, profile, representatives, title = "Register Your Organization" }: OrganizationProps) => {
  type DropDownOption = {
    Id: number
    Value: string | ''
    ValueEn: string | ''
    ValueAr: string | ''
  }
  type DropDown = Array<DropDownOption>
  const [sectors, setSectors] = useState<DropDown>([])
  const [industries, setIndustries] = useState<DropDown>([])
  const [organizations, setOrganizations] = useState<DropDown>([])
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [alert, setAlert] = useState<string>('')
  const formRef = createRef<HTMLFormElement>()
  const telOrganizationRef = createRef<IntlTelInput>()
  const telCEORef = createRef<IntlTelInput>()
  const telChairmanRef = createRef<IntlTelInput>()
  const isSubsidiaryRef = createRef<HTMLInputElement>()
  const parentOrganiztionRef = createRef<HTMLSelectElement>()
  const representative1Ref = createRef<HTMLInputElement>()
  const representative2Ref = createRef<HTMLInputElement>()
  const representative3Ref = createRef<HTMLInputElement>()
  const navigate = useNavigate()
  const { id: organizationId } = useParams()

  const getDropDowns = async () => {
    const [sectorData, industryData, organizationData] = await Promise.all([
      fetch(SECTOR_TYPES).then((response) => response.json()),
      fetch(INDUSTRY_TYPES).then((response) => response.json()),
      fetch(ORGANIZATION_NAMES).then((response) => response.json()),
    ])
    setSectors(sectorData)
    setIndustries(industryData)
    setOrganizations(organizationData)
    setLoading(false)
  }
  useEffect(() => { getDropDowns() }, [])

  useEffect(() => {
    const form = formRef.current
    if (form) {
      Object.entries(profile ?? {}).forEach(([key, value]) => {
        const namedItem = form.querySelector(`[name=${key}]`) as HTMLInputElement
        if (key === 'Parent' && value === null) value = 0
        if (namedItem && namedItem.type !== 'file')
          namedItem.value = value as string
        if (key === 'Telephone')
          telOrganizationRef.current?.updateValFromNumber(value as string)
        if (key === 'CEOTelephone')
          telCEORef.current?.updateValFromNumber(value as string)
        if (key === 'ChairmanTelephone')
          telChairmanRef.current?.updateValFromNumber(value as string)
        if (key === 'Website') {
          const { protocol, host } = new URL(value as string)
          const urlSelect = form.querySelector(`[name=URL]`) as HTMLInputElement
          urlSelect.value = `${protocol}//`
          namedItem.value = host
        }
        if (key === 'IsSubsidiary')
          namedItem.checked = value as boolean
      })
      const [representative1 = '', representative2 = '', representative3 = ''] =
        representatives?.map((representative) => representative.Email) ?? ['', '', '']
      if (representative1Ref.current)
        representative1Ref.current.value = representative1
      if (representative2Ref.current)
        representative2Ref.current.value = representative2
      if (representative3Ref.current)
        representative3Ref.current.value = representative3
    }
  }, [formRef])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    event.currentTarget.classList.add('was-validated')
    if (!event.currentTarget.checkValidity()) { return }
    setLoading(true)
    const formData = new FormData(event.target as HTMLFormElement)
    formData.set('IsSubsidiary',
      isSubsidiaryRef.current?.checked && parentOrganiztionRef.current?.value !== '0' && parentOrganiztionRef.current?.value
        ? 'true'
        : 'false')
    const json = await submitForm(ORGANIZATION_REGISTRATION, formData)
    if (json.type === 'SUCCESS') {
      if (isEditing) navigate(-1)
      else setSuccess(true)
      return
    } else setLoading(false)
    let alertHtml = `<div>${json.message}</div>`
    setAlert(alertHtml)
    if (!json.errors) { return }
    alertHtml += '<ul class="m-0">'
    for (const error of json.errors) {
      alertHtml += `<li>${error.Code}: ${error.Description}</li>`
    }
    alertHtml += '</ul>'
    setAlert(alertHtml)
  }
  const generateCSV: React.MouseEventHandler<HTMLButtonElement> = async event => {
    const button = event.currentTarget
    button.disabled = true
    const [applicants]: [Array<Applicant>] = await Promise.all([
      fetch(GET_APPROVALS).then(response => response.json()),
    ])
    let lines = ''
    const columns = [
      'FirstNameEn',
      'MiddleNameEn',
      'LastNameEn',
      'FirstNameAr',
      'MiddleNameAr',
      'LastNameAr',
      'Event',
      'EventType',
      'FromDate',
      'ToDate',
      'Deadline',
      'Approved',
      'Status',
    ]
    applicants.forEach((applicant, index) => {
      const flattened =
        applicant.Data && typeof applicant.Data !== 'string'
          ? { ...applicant, ...applicant.Data }
          : { ...applicant }
      delete flattened.Data
      const tuples = Object.entries(flattened)
      if (index === 0) {
        for (const [key, value] of tuples) {
          if (!columns.includes(key)) { continue }
          lines += `"${escapeData(key)}",`
        }
        lines += '\n'
      }
      for (const [key, value] of tuples) {
        if (!columns.includes(key)) { continue }
        lines += value ? `"${escapeData(value as string)}",` : `"",`
      }
      lines += '\n'
    })
    const today = new Date()
    const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`
    downloadCSVFile(lines, `${date} - ${profile?.NameEn ?? 'Organization'} - Training Data`)
    button.disabled = false
    setToastMessage('Download Successful✅')
    setShowToast(true)
  }

  if (!isEditing && success) return (
    <div className="mt-5">
      <Generic title="Thank you">
        <p>Your application has been received and will be reviewed by our staff shortly.</p>
        <p>Upon review finalization an email will be sent with the result.</p>
      </Generic>
    </div>)
  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">{title}</h1>
        {isEditing &&
          <Toolbar>
            <Button onClick={generateCSV} title="Download">
              <Download />
              <div><small>Download</small></div>
            </Button>
          </Toolbar>}
      </div>
      <Form
        action={ORGANIZATION_REGISTRATION}
        method="post"
        onSubmit={onSubmit}
        ref={formRef}
        noValidate
      >
        {alert.length > 0 && (
          <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: alert }}></div>
        )}
        <small className="text-secondary">** Please note that all fields are mandatory.</small>
        <div className="display-6 mb-4 text-bold">Organization Information</div>
        <InputGroup className="mb-3">
          <FormControl
            id="NameEn"
            type="text"
            name="NameEn"
            placeholder="Company Name"
            required
          />
          <FormControl
            id="NameAr"
            type="text"
            name="NameAr"
            placeholder="اسم الشركة"
            dir="rtl"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <Select id="Sector" name="Sector" defaultValue={""} required>
            <option value="" disabled>
              Sector Type
            </option>
            {sectors.map((sector, index) => (
              <option value={sector.Id} key={"sector" + index}>
                {sector.Value}
              </option>
            ))}
          </Select>
          <Select id="Industry" name="Industry" defaultValue={""} required>
            <option value="" disabled>
              Industry Type
            </option>
            {industries.map((industry, index) => (
              <option value={industry.Id} key={"industry" + index}>
                {industry.Value}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup className="mb-3">
          {/* <Col xs={1}> */}
          <Select id="URL" name="URL" defaultValue={"https://"} style={{ paddingInlineEnd: '14px' }} required>
            <option value="http://">
              http://
            </option>
            <option value="https://">
              https://
            </option>
          </Select>
          {/* </Col>
        <Col md={5}> */}
          <FormControl
            id="Website"
            type="text"
            name="Website"
            placeholder="Company Website"
            required
          />
          {/* </Col> */}
        </InputGroup>
        <FormControl
          id="Address"
          type="text"
          name="Address"
          placeholder="Address"
          required
        />
        <InputGroup className="mb-3">
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="Email"
            type="email"
            name="Email"
            placeholder="Company Email"
            required
          />
          <IntlTelInput
            fieldId="Telephone"
            containerClassName="intl-tel-input form-control p-0 pe-4"
            inputClassName="form-control border-0"
            excludeCountries={["il"]}
            defaultCountry="kw"
            allowDropdown={false}
            fieldName="Telephone"
            ref={telOrganizationRef}
            telInputProps={{
              required: true,
              pattern: "[0-9]{7,8}"
            }}
            placeholder={"Company Telephone"}
            defaultValue={isEditing ? profile?.Telephone : undefined}
            format={false}
            formatOnInit={false}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="EmployeesCount"
            type="number"
            name="EmployeesCount"
            placeholder="Estimated Number of Employees"
            required
          />
          <FormControl
            id="KuwaitiEmployeesCount"
            type="number"
            name="KuwaitiEmployeesCount"
            placeholder="Number of Kuwaiti Employees"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="CEONameEn"
            type="text"
            name="CEONameEn"
            placeholder="Company CEO Name"
            required
          />
          <FormControl
            id="CEONameAr"
            type="text"
            name="CEONameAr"
            placeholder="اسم الرئيس التنفيذي"
            dir="rtl"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="CEOEmail"
            type="email"
            name="CEOEmail"
            placeholder="Company CEO Email"
            required
          />
          <IntlTelInput
            fieldId="CEOTelephone"
            containerClassName="intl-tel-input form-control p-0 pe-4"
            inputClassName="form-control border-0"
            excludeCountries={["il"]}
            defaultCountry="kw"
            allowDropdown={false}
            fieldName="CEOTelephone"
            ref={telCEORef}
            telInputProps={{
              required: true,
              pattern: "[0-9]{8}"
            }}
            placeholder={"CEO Telephone"}
            defaultValue={isEditing ? profile?.CEOTelephone : undefined}
            format={false}
            formatOnInit={false}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="ChairmanNameEn"
            type="text"
            name="ChairmanNameEn"
            placeholder="Company Chairman Name"
            required
          />
          <FormControl
            id="ChairmanNameAr"
            type="text"
            name="ChairmanNameAr"
            placeholder="اسم رئيس مجلس الإدارة"
            dir="rtl"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="ChairmanEmail"
            type="email"
            name="ChairmanEmail"
            placeholder="Company Chairman Email"
            required
          />
          <IntlTelInput
            fieldId="ChairmanTelephone"
            containerClassName="intl-tel-input form-control p-0 pe-4"
            inputClassName="form-control border-0"
            excludeCountries={["il"]}
            defaultCountry="kw"
            allowDropdown={false}
            fieldName="ChairmanTelephone"
            ref={telChairmanRef}
            telInputProps={{
              required: true,
              pattern: '[0-9]{8}'
            }}
            placeholder={"Chairman Telephone"}
            defaultValue={isEditing ? profile?.ChairmanTelephone : undefined}
            format={false}
            formatOnInit={false}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text>Company Establishment</InputGroup.Text>
          <FormControl
            id="Establishment"
            type="date"
            name="Establishment"
            required
          />
        </InputGroup>
        <Form.Check
          type="switch"
          id="IsSubsidiary"
          name="IsSubsidiary"
          label="Subsidiary Company"
          ref={isSubsidiaryRef}
        />
        <InputGroup className="mb-3">
          <InputGroup.Text>Parent Company</InputGroup.Text>
          <Form.Select
            id="Parent"
            name="Parent"
            defaultValue={"0"}
            ref={parentOrganiztionRef}
            required>
            <option value="0">None</option>
            {organizations
              .sort(({ ValueEn: a }: DropDownOption, { ValueEn: b }: DropDownOption) =>
                a === b
                  ? 0
                  : a > b
                    ? 1
                    : -1)
              .map((organization, index) => (
                <option value={organization.Id} key={"organization" + index}>
                  {organization.ValueEn}
                </option>
              ))}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Business License</Form.Label>
                {profile ? (
                  <a
                    href={ORGANIZATION_FILE`${profile?.License}`}
                    download
                  >
                    <p>Download</p>
                  </a>
                ) : (
                  <Form.Control
                    id="License"
                    type="file"
                    accept=".pdf,image/*"
                    name="License"
                    required={!isEditing}
                  />
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Supplemental Material</Form.Label>
                {profile ? (
                  profile?.Supplementary?.map((file, index) => (
                    <a href={DOWNLOAD`${file.UniqueId}`} key={`file${index}`}>
                      <p>Download</p>
                    </a>
                  ))
                ) : (
                  <Form.Control
                    id="Supplementary"
                    type="file"
                    accept=".pdf,image/*"
                    name="Supplementary"
                    multiple
                  // required={!isTitleHidden ? true : false}
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
        </InputGroup>
        <div className="display-6 mb-4 text-bold">Contact Information</div>
        <p>
          The following HR Representative(s) emails will (when an account is created) have privileges
          to manage the organization.
        </p>
        <InputGroup className="mb-3">
          <FormControl
            id="Representative1"
            type="email"
            name="Representative1"
            placeholder="Primary HR Representative Email"
            ref={representative1Ref}
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="Representative2"
            type="email"
            name="Representative2"
            placeholder="Secondary Contact HR Representative (Optional)"
            ref={representative2Ref}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="Representative3"
            type="email"
            name="Representative3"
            placeholder="Tertiary Contact HR Representative (Optional)"
            ref={representative3Ref}
          />
        </InputGroup>
        {
          isEditing
            ?
            (<>
              <FormControl type="hidden" name="IsUpdating" value="true" />
              <FormControl type="hidden" name="OrganizationId" value={organizationId} />
              <Button type="submit" className="mb-4">
                Update
              </Button>
            </>)
            :
            (<Button type="submit" className="mb-4">
              Register
            </Button>)
        }
      </Form>
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

export default Organization
