import * as React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { AcceptedApplicant } from '../types/applicant'
import { stringFilter } from '../utils/filters'
import Form from 'react-bootstrap/Form'
import CertificateFileInput from '../components/Forms/CertificateFileInput'

const STATUS_ATTENDANCE = {
  "1": "Pending",
  "2": "Withdrawn",
  "3": "Fully Attended",
  "4": "Partially Attended",
}

type AcceptedApplicantsTableColumnsProps = {
  onAttendanceChange: React.ChangeEventHandler<HTMLSelectElement>,
  onFeedbackChange: React.ChangeEventHandler<HTMLInputElement>,
}

const acceptedApplicantsTableColumns = ({ onAttendanceChange, onFeedbackChange }: AcceptedApplicantsTableColumnsProps): ColumnDef<AcceptedApplicant>[] => [
  {
    header: () => <span>Id</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Id',
    cell: info => info.getValue(),
    filterFn: stringFilter,
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>First Name</span>,
    accessorKey: 'FirstName',
    accessorFn: row => row.FirstName,
    id: 'FirstName',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Middle Name</span>,
    accessorKey: 'MiddleName',
    accessorFn: row => row.MiddleName,
    id: 'MiddleName',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Last Name</span>,
    accessorKey: 'LastName',
    accessorFn: row => row.LastName,
    id: 'LastName',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Attendance</span>,
    accessorKey: 'Attendance',
    accessorFn: row => ({ Id: row.Id, Attendance: row.Attendance }),
    id: 'Attendance',
    cell: info => {
      const { Id, Attendance } = info.getValue<{ Id: number, Attendance: number }>()
      return <Form.Select
        onChange={onAttendanceChange}
        defaultValue={Attendance}
        data-id={`${Id}`}
      >
        <option className="text-dark" value="1" label="Pending">
          Pending
        </option>
        <option className="text-dark" value="2" label="Withdrawn">
          Withdrawn
        </option>
        <option value="3" label="Fully Attended">
          Fully Attended
        </option>
        <option value="4" label="Partially Attended">
          Partially Attended
        </option>
        <option value="5" label="Did Not Attend">
          Did Not Attend
        </option>
      </Form.Select>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue<any>(columnId).Attendance
      const statusString = STATUS_ATTENDANCE[value as keyof typeof STATUS_ATTENDANCE].toLowerCase()
      return Boolean(statusString.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = STATUS_ATTENDANCE[current.original.Attendance as keyof typeof STATUS_ATTENDANCE].toLowerCase()
      const nextStatus = STATUS_ATTENDANCE[next.original.Attendance as keyof typeof STATUS_ATTENDANCE].toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    }
  },
  {
    header: () => <span>Feedback Completion</span>,
    accessorKey: 'FeedbackCompletion',
    accessorFn: row => ({ Id: row.Id, FeedbackCompletion: row.FeedbackCompletion }),
    id: 'FeedbackCompletion',
    cell: info => {
      const { Id, FeedbackCompletion } = info.getValue<{ Id: number, FeedbackCompletion: boolean | null }>()
      return <Form.Check
        onChange={onFeedbackChange}
        type="switch"
        id={`feedback-${Id}`}
        name="Feedback"
        title="Feedback Completed"
        defaultChecked={FeedbackCompletion !== null}
        data-id={`${Id}`}
      />
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = current.original.FeedbackCompletion
      const nextStatus = next.original.FeedbackCompletion
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    },
    enableColumnFilter: false,
  },
  {
    header: () => <span>Certificate</span>,
    accessorKey: 'Certificate',
    accessorFn: row => ({ Id: row.Id, Certificate: row.Certificate }),
    id: 'Certificate',
    cell: info => {
      const { Id, Certificate } = info.getValue<{ Id: number, Certificate: string | null }>()
      return <CertificateFileInput
        formLabel="Certificate"
        name="Certificate"
        id={`${Id}`}
        accept="image/*,.pdf"
        hasFile={Certificate != null}
      />
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  STATUS_ATTENDANCE,
  acceptedApplicantsTableColumns,
}