//React
import React from "react";
import { useState, useEffect } from "react";
//Bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import BootstrapSpinner from "react-bootstrap/Spinner";
import Spinner from "../../components/Spinner/Spinner";
//React Router dom
import { Link, useParams } from "react-router-dom";
type Props = {};
const questions: string[] = [
  "Briefly describe your role and responsibilities in your organization.",
  "What attracted you to apply to this program?",
  "What attracted you to apply to this program?",
  "How will this program help your organization or department in general?",
  "Please describe any specific challenges, projects, or immediate goals you or your organization/department have that you hope this program will help with.",
];

type Answer = {
  id: number;
  answer: string;
  score: number;
};
const Application = (props: Props) => {
  //hooks
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { id } = useParams();
  useEffect(() => {
    //dummy data for applicants
    const generatedAnswers: Answer[] = [];
    for (let i = 1; i <= 5; i++) {
      const answer: Answer = {
        id: i,
        answer: `lorem`,
        score: i % 6,
      };
      generatedAnswers.push(answer);
    }
    setAnswers(generatedAnswers);
  }, []);
  //handlers
  const updateChanges = () => {
    setToastMessage("Changes Updated✅");
    setShowToast(true);
  };
  if (answers.length == 0) {
    return (
      <BootstrapSpinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </BootstrapSpinner>
    );
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative">
        <h1 className="display-6 mb-4 text-primary">
          Application - Event Name - {id}
        </h1>
        <Button onClick={updateChanges}>Update Changes</Button>
      </div>
      {/* QUESTIONS */}
      <h4 className="mb-4 text-capitalize">Questions</h4>
      <Form>
        {questions.map((question, i) => (
          <Form.Group
            className="mb-4"
            controlId={`question-${i}`}
            key={`question-${i}`}
          >
            <Form.Label>{question}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              required
              maxLength={1500}
              readOnly
              value={answers[i].answer}
            />
            <ScoringGrade score={answers[i].score} />
            <hr />
          </Form.Group>
        ))}
      </Form>
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
type ScoreProps = {
  score: number;
};
const ScoringGrade = (props: ScoreProps) => {
  const selectedScore = props.score;
  return (
    <Form.Group className="mb-3" controlId="status">
      Score awarded:
      <Form.Select>
        <option className="text-dark" value="0" selected={selectedScore == 0}>
          Undecided
        </option>
        <option className="text-dark" value="1" selected={selectedScore == 1}>
          1
        </option>
        <option value="2" selected={selectedScore == 2}>
          2
        </option>
        <option value="3" selected={selectedScore == 3}>
          3
        </option>
        <option className="text-dark" value="4" selected={selectedScore == 4}>
          4
        </option>
        <option className="text-dark" value="5" selected={selectedScore == 5}>
          5
        </option>
      </Form.Select>
    </Form.Group>
  );
};
export default Application;
