import './PaginationBar.css'
import * as React from 'react'
import { Table } from '@tanstack/react-table'
import { Button, FormControl, Form } from 'react-bootstrap'

type PaginationBarProps = {
  table: Table<any>
}

const PaginationBar = ({ table }: PaginationBarProps) => (
  <div className="d-flex justify-content-between align-items-end mb-2">
    <div className="flex items-center">
      <div className="d-inline-block">
        <div>{`Page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}</div>
        <Button
          className="d-inline-flex align-items-center me-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24"><path d="M13 12l11-7v14l-11-7zm-11 0l11-7v14l-11-7zm-2 6h2v-12h-2v12z"/></svg>
        </Button>
        <Button
          className="d-inline-flex align-items-center me-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 24 24"><path d="M4 2v20h-2v-20h2zm18 0l-16 10 16 10v-20z" /></svg>
        </Button>
        <Button
          className="d-inline-flex align-items-center me-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 24 24"><path d="M20 22v-20h2v20h-2zm-18 0l16-10-16-10v20z" /></svg>
        </Button>
        <Button
          className="d-inline-flex align-items-center me-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24"><path d="M0 19v-14l11 7-11 7zm11 0v-14l11 7-11 7zm13-13h-2v12h2v-12z"/></svg>
        </Button>
      </div>
      <div className="d-inline-block">
        <div>Go to page:</div>
        <FormControl
          type="number"
          value={table.getState().pagination.pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            table.setPageIndex(page)
          }}
          className="w-auto pb-2"
        />
      </div>
    </div>
    <div className="flex items-center">
      <div>Page size:</div>
      <Form.Select
        defaultValue={table.getState().pagination.pageSize}
        onChange={e => {
          table.setPageSize(Number(e.target.value))
        }}
        className="w-auto"
      >
        {[10, 25, 50, 100].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </Form.Select>
    </div>
  </div>
)

export default PaginationBar
