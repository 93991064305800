//React
import React from "react";
import { useState, useEffect } from "react";
//React Router
import { Link, useNavigate } from "react-router-dom";
//Bootstrap
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
//Bootstrap Icons
import { Plus, Files, Trash, PencilSquare } from "react-bootstrap-icons";
//Search
//Filter
import Filter from "../../components/Filter/Filter";
import Search from "../../components/Search/Search";
const filters = [
  {
    category: "Classification",
    options: ["OE", "AEE", "LEE", "Certified", "HIPO", "IC", "Custom"],
  },
];
type Props = {};
enum classification {
  OE,
  AEE,
  LEE,
  Certified,
  HIPO,
  IC,
  Custom,
}
type Criteria = {
  id: number;
  title: string;
  associatedEvents: number[];
  createdOn: Date;
  type: string;
};
const Criterion = (props: Props) => {
  //hooks
  const [show, setShow] = useState(false);
  const [id, setId] = useState(0);
  const [criterion, setCriterion] = useState<Criteria[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    //dummy data for criterion
    const generatedCriteria: Criteria[] = [];
    for (let i = 1; i <= 20; i++) {
      const criteria: Criteria = {
        id: i,
        title: `KFAS-criteria-${i}`,
        createdOn: new Date(2022, 1, 1),
        associatedEvents: [12, 1222, 44],
        type: classification[id % 5],
      };
      generatedCriteria.push(criteria);
    }
    setCriterion(generatedCriteria);
  }, []);
  //Handlers
  const handleClose = () => setShow(false); //modal
  const handleShow = () => setShow(true); //modal
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    setId(Number(event.currentTarget.getAttribute("data-id")));
    handleShow();
  };
  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = Number(event.currentTarget.getAttribute("data-id"));
    setId(selectedId);
    navigate(`/admin/event-info/${selectedId}`);
  };
  const handleDuplicate = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = Number(event.currentTarget.getAttribute("data-id"));
    //send selectedId in request to backend
    setId(selectedId);
    //update events state
    const duplicatedCriteria: Criteria = {
      id: Math.floor(Math.random() * 100),
      title: `Copy - KFAS-criteria-${selectedId}`,
      createdOn: new Date(2022, 1, 1),
      associatedEvents: [12, 1222, 44],
      type: classification[id % 5],
    };
    setCriterion([...criterion, duplicatedCriteria]);
    setToastMessage("Criteria Duplicated ✅");
    setShowToast(true);
  };
  const deleteEvent = () => {
    setCriterion(criterion.filter((criteria) => criteria.id != id));
    setToastMessage("Criteria Deleted 🚩");
    setShowToast(true);
    handleClose();
  };

  return (
    <>
      <div className="position-relative">
        <h1 className="display-6 mb-4 text-primary">Criterion</h1>
      </div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        {/* <Search /> */}
        <div className="d-flex align-items-center">
          {/* <Filter filters={filters} /> */}
          <Link className="btn btn-primary text-white" to="/admin/criteria/new">
            <Plus size={25} className="me-1" />
            Add
          </Link>
        </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Created On</th>
            <th className="text-center">Associated Offerings</th>
          </tr>
        </thead>
        <tbody>
          {criterion.map((criteria) => (
            <tr key={`criteria-${criteria.id}`}>
              <td>{criteria.id}</td>
              <td>{criteria.title}</td>
              <td>{criteria.createdOn.toDateString()}</td>
              <td className="text-center">
                {criteria.associatedEvents.length}
              </td>
              <td>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  <Button
                    className="mb-3 me-0 mb-md-0 me-md-3 btn-action border-0 bg-transparent"
                    data-id={criteria.id}
                    onClick={handleEdit}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                  >
                    <PencilSquare size={25} />
                    {/* Edit */}
                  </Button>
                  <Button
                    className="mb-3 me-0 mb-md-0 me-md-3 btn-action border-0 bg-transparent"
                    data-id={criteria.id}
                    onClick={handleDuplicate}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Duplicate"
                  >
                    {/* Duplicate */}
                    <Files size={25} />
                  </Button>
                  <Button
                    className="btn-action border-0 bg-transparent"
                    data-id={criteria.id}
                    onClick={handleDelete}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Delete"
                    //you should not delete criteria associated with an event
                    disabled={true}
                  >
                    <Trash size={25} />
                    {/* Delete */}
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Criteria <span className="fw-bold text-primary">#{id}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the criteria with Id{" "}
          <span className="fw-bold text-primary">#{id}</span>? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteEvent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Criterion;
