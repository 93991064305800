import React from 'react'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { submitForm } from '../../utils/forms'

type CertificateFileInputProps = {
  formLabel: string
  accept: string
  id: string
  name: string
  hasFile: boolean
  required?: boolean
}

const CertificateFileInput = ({
  formLabel,
  accept,
  id,
  name,
  hasFile,
  required = true,
}: CertificateFileInputProps) => {
  const [fileExists, setFileExists] = useState(hasFile)
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()
    formData.append('Certificate', e.target.files?.[0] ?? '')
    formData.append('Id', id.toString())
    try {
      const json = await submitForm(
        `/api/data/UploadCertificate?id=${id}`,
        formData
      )
    } catch (e) { }
  }
  return (
    <Form.Group className="mb-3">
      <Form.Label>{formLabel}</Form.Label>
      {!fileExists && (
        <Form.Control
          type="file"
          accept={accept}
          required={required}
          id={id ?? name}
          name={name}
          onChange={handleChange}
        />
      )}
      <div className="d-flex justify-content-between">
        {fileExists && (
          <a
            href={`/api/data/applicantCertificate?id=${id}&fileName=${name}`}
            download
          >
            Download
          </a>
        )}
        {fileExists && (
          <Button
            className="btn btn-secondary"
            onClick={() => setFileExists(false)}
          >
            Remove
          </Button>
        )}
      </div>
    </Form.Group>
  )
}

export default CertificateFileInput
