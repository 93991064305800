/**
 * function to store single state in local storage
 */
export const saveState = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(key, serializedState)
  } catch { } // ignore write errors
}

/**
* function to get single persistent state from localstorage
*/
export const loadState = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key)
    if (serializedState === null) { return undefined }
    return JSON.parse(serializedState)
  } catch (err) { return undefined }
}