import * as React from "react";
import { Link } from "react-router-dom";
//Types
import { Event } from "../../types/event";
import EventCard from "../EventCard/EventCard";

//props
type EventsProps = {
  events: Event[];
};
const Events = ({ events }: EventsProps) => {
  return (
    <div className="d-flex flex-column p-2 mt-5">
      <h1 className="text-center display-4">Offerings</h1>
      <div className="d-flex justify-content-end align-items-center mb-4 position-relative">
        <Link
          className="ms-auto btn btn-outline-primary btn-sm position-absolute"
          to="/event-list"
          style={{ bottom: "15px" }}
        >
          See All
        </Link>
      </div>
      <div className="d-flex align-items-stretch justify-content-center flex-wrap gap-4">
        {events.map((event) => (<EventCard event={event} key={`event-${event.Id}`} />))}
      </div>
    </div>
  );
};

export default Events;
