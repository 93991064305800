import './Individual.css'
import * as React from 'react'
import { useState, useEffect, createRef } from 'react'
import Form from 'react-bootstrap/Form'
import Label from 'react-bootstrap/FormLabel'
import Select from 'react-bootstrap/FormSelect'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Spinner from '../Spinner/Spinner'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import Countries from 'i18n-iso-countries'
import { submitForm } from '../../utils/forms'
import { UserProfile } from '../../types/profile'
import { useParams, Route } from 'react-router'
import { useNavigate } from 'react-router'
import FileInput from './FileInput'
import { useAppSelector } from '../../store/hooks'
import Toolbar from '../Toolbar/Toolbar'

Countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
interface IndividualProps {
  title?: string
  isEditing?: boolean
  profile?: UserProfile
}
interface FileNames {
  civilId: string
  cv: string
}
type DropDownOption = {
  Id: number
  Value: string | ''
  ValueEn: string | ''
  ValueAr: string | ''
}
type DropDown = Array<DropDownOption>
const initialFileNames = {
  civilId: 'Choose Civil Id File',
  cv: 'Choose CV File',
}
const AUTH_STATE = {
  LOGGED_IN: true,
  LOGGED_OUT: false,
}
const USER_READ_ONLY = [
  'FirstNameEn',
  'MiddleNameEn',
  'LastNameEn',
  'FirstNameAr',
  'MiddleNameAr',
  'LastNameAr',
  'Nationality',
  'Gender',
  'CivilId',
  'Birthday',
  'Organization',
  'CareerStart'
]
const HR_READ_ONLY = [
  'OtherEmail',
  'Nationality',
  'Gender',
  'Telephone',
  'CivilId',
  'CivilIdImage',
  'Birthday',
  'CareerStart',
  'CV'
]

const Individual: React.FC<IndividualProps> = ({ isEditing, profile, title = "Register" }: IndividualProps) => {
  const state: 'LOGGED_IN' | 'LOGGED_OUT' = useAppSelector(selector => selector.auth.state)
  const [fileNames, setFileNames] = useState<FileNames>(initialFileNames)
  const [organizations, setOrganizations] = useState<DropDown>([])
  const [departments, setDepartments] = useState<DropDown>([])
  const [careers, setCareers] = useState<DropDown>([])
  const [education, setEducation] = useState<DropDown>([])
  const [loading, setloading] = useState(true)
  const [alert, setAlert] = useState<string>('')
  const [role, setRole] = useState('')
  const formRef = createRef<HTMLFormElement>()
  const telRef = createRef<IntlTelInput>()
  const navigate = useNavigate()
  const { id: userId } = useParams()

  const getDropDowns = async () => {
    const [organizationData, departmentData, careerData, educationData] =
      await Promise.all([
        fetch(`/api/data/organizationnames`).then((response) => response.json()),
        fetch(`/api/data/departmentnames`).then((response) => response.json()),
        fetch(`/api/data/careernames`).then((response) => response.json()),
        fetch(`/api/data/educationtypes`).then((response) => response.json()),
      ])
    setOrganizations(organizationData)
    setDepartments(departmentData)
    setCareers(careerData)
    setEducation(educationData)
    setloading(false)
  }
  useEffect(() => { getDropDowns() }, [])

  const readOnlyFields = (readOnly: Array<string>) => {
    const form = formRef.current
    if (form) {
      const fields = form.querySelectorAll('input, select, textarea')
      fields.forEach(field => {
        const isDisabled = readOnly.includes(field.getAttribute('name') ?? '')
        if (isDisabled) field.setAttribute('disabled', 'disabled')
        else field.removeAttribute('disabled')
      })
    }
  }
  const getUserRole = async () => {
    if (!AUTH_STATE[state]) { return }
    const role = await fetch(`/api/data/UserRole`)
      .then(response => response.text())
    setRole(role)
    if (role === 'admin') { return }
    if (role === 'representative') readOnlyFields(HR_READ_ONLY)
    if (role === 'user' || userId === ':id') readOnlyFields(USER_READ_ONLY)
  }
  useEffect(() => { getUserRole() }, [])

  useEffect(() => {
    if (profile === undefined && role === '') { return }
    if (role !== 'representative') { return }
    setOrganizations(organizations => organizations.filter(org => org.Id === profile?.Organization))
  }, [profile, organizations, role])

  useEffect(() => {
    const form = formRef.current
    if (form) {
      Object.entries(profile ?? {}).forEach(([key, value]) => {
        const namedItem = form.querySelector(
          `[name=${key}]`
        ) as HTMLInputElement
        if (key === 'Organization' && value === null) value = 0
        if (namedItem && namedItem.type !== 'file')
          namedItem.value = value as string
        if (key === 'Telephone') {
          telRef.current?.updateValFromNumber(value as string)
        }
      })
    }
  }, [formRef])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    event.currentTarget.classList.add('was-validated')
    if (!event.currentTarget.checkValidity()) {
      return
    }
    setloading(true)
    const formData = new FormData(event.target as HTMLFormElement)
    Array.from(document.querySelectorAll('[disabled]')).forEach((field: any) => {
      formData.set(field.getAttribute('name') ?? '', field.value ?? '')
    })
    const json = await submitForm(`/api/registration/individual`, formData)
    if (json.type === 'SUCCESS') {
      if (isEditing) navigate(-1)
      else navigate('/login')
      return
    } else setloading(false)
    let alertHtml = `<div>${json.message}</div>`
    setAlert(alertHtml)
    if (!json.errors) {
      return
    }
    alertHtml += '<ul class="m-0">'
    for (const error of json.errors) {
      alertHtml += `<li>${error.Code}: ${error.Description}</li>`
    }
    alertHtml += '</ul>'
    setAlert(alertHtml)
  }

  if (loading) return <Spinner />
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">{title}</h1>
        {isEditing &&
          <Toolbar>
          </Toolbar>}
      </div>
      <Form
        action="/api/registration/individual"
        method="post"
        onSubmit={onSubmit}
        ref={formRef}
        noValidate
      >
        {alert.length > 0 && (
          <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{ __html: alert }}
          ></div>
        )}
        <small className="text-secondary">
          ** Please note that all fields are mandatory.
        </small>
        <div className="display-6 mb-4 text-bold">Personal Information</div>
        <InputGroup className="mb-3">
          <FormControl
            id="FirstNameEn"
            type="text"
            name="FirstNameEn"
            placeholder="First Name"
            required
          />
          <FormControl
            id="MiddleNameEn"
            type="text"
            name="MiddleNameEn"
            placeholder="Middle Name"
            required
          />
          <FormControl
            id="LastNameEn"
            type="text"
            name="LastNameEn"
            placeholder="Last Name"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3" dir="rtl">
          <FormControl
            id="FirstNameAr"
            type="text"
            name="FirstNameAr"
            placeholder="الاسم الأول"
            required
          />
          <FormControl
            id="MiddleNameAr"
            type="text"
            name="MiddleNameAr"
            placeholder="الاسم الأوسط"
            required
          />
          <FormControl
            id="LastNameAr"
            type="text"
            name="LastNameAr"
            placeholder="الاسم الأخير"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <FormControl
            id="WorkEmail"
            type="email"
            name="WorkEmail"
            placeholder="Work Email"
            required
          />
          <FormControl
            id="OtherEmail"
            type="email"
            name="OtherEmail"
            placeholder="Personal Email"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <Form.Select
            id="Nationality"
            aria-label="nationality"
            defaultValue={""}
            name="Nationality"
            required
          >
            <option value="" disabled>
              Nationality
            </option>
            {Object.values(
              Countries.getNames("en", {
                select: "official",
              })
            )
              .sort()
              .filter((country) => country != "Israel")
              .map((country) => (
                <option value={country} key={country}>
                  {country}
                </option>
              ))}
          </Form.Select>
          <Select
            id="Gender"
            className="form-control"
            name="Gender"
            defaultValue={""}
            required
          >
            <option value="" disabled>
              Gender
            </option>
            <option value="false">Male</option>
            <option value="true">Female</option>
          </Select>
        </InputGroup>
        <InputGroup className="mb-3 d-flex">
          <IntlTelInput
            fieldId="Telephone"
            containerClassName="intl-tel-input form-control p-0 pe-4"
            inputClassName="form-control border-0"
            excludeCountries={["il"]}
            defaultCountry="kw"
            allowDropdown={false}
            fieldName="Telephone"
            defaultValue={isEditing ? profile?.Telephone : undefined}
            ref={telRef}
            telInputProps={{
              required: true,
              pattern: "[0-9]{8}",
            }}
            placeholder={"Telephone"}
            format={false}
            formatOnInit={false}
          />
          <FormControl
            id="CivilId"
            type="text"
            name="CivilId"
            placeholder="Civil Id Number"
            readOnly={isEditing}
            pattern="\d{12}"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3 d-flex">
          <InputGroup.Text>Civil Id Image</InputGroup.Text>
          <FileInput
            id="CivilIdImage"
            name="CivilIdImage"
            placeholder="Civil Id"
            fileId={profile?.CivilIdImage ?? ""}
            accept="*"
            hasFile={
              profile !== undefined &&
              profile?.CivilIdImage !== "" &&
              profile?.CivilIdImage !== "00000000-0000-0000-0000-000000000000"
            }
            onChange={(event) => {
              const values = event.currentTarget.value.split("\\")
              const name =
                values.length == 1
                  ? "Choose Civil Id File"
                  : values[values.length - 1]
              setFileNames({ ...fileNames, civilId: name })
            }}
            formGroup={false}
            required={!isEditing}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text>Date of Birth</InputGroup.Text>
          <FormControl id="Birthday" type="date" name="Birthday" required />
        </InputGroup>
        <div className="display-6 mb-4 text-bold">Work Information</div>
        <div
          className="alert alert-danger"
          role="alert"
        >
          If your company is not in the drop down menu please do not select an alternate company.
          Only employees of companies listed in the drop down on KFAS Learn can register for programs on the platform.
          If the company name does not appear in the drop down menu it is not registered on the platform.
          Please contact your HR focal point regarding registering the company on KFAS Learn.
          The HR focal point is required to create a company account so employees can register for training programs.
          For more information please contact <a href="mailto:training@kfas.org.kw" aria-label="Email">training@kfas.org.kw</a>.
        </div>
        <InputGroup className="mb-3">
          <FormControl
            id="Title"
            type="text"
            name="Title"
            placeholder="Current Title/Position"
            required
          />
          <FormControl
            id="EmployeeNumber"
            type="text"
            name="EmployeeNumber"
            placeholder="Employee Number (optional)"
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <Select id="Organization" name="Organization" defaultValue={""} required>
            <option value="" disabled>
              Organization
            </option>
            {role === 'admin' || role === 'representative'
              ? <option value="0">Other</option>
              : <></>}
            {organizations
              .sort((a, b) => a.ValueEn.localeCompare(b.ValueEn, 'en', { sensitivity: 'base', ignorePunctuation: true }))
              .map((organization, index) => (
                <option value={organization.Id} key={"organization" + index}>
                  {organization.ValueEn}
                </option>
              ))}
          </Select>
          <Select id="Department" name="Department" defaultValue={""} required>
            <option value="" disabled>
              Department
            </option>
            {departments.map((department, index) => (
              <option value={department.Id} key={"department" + index}>
                {department.Value}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <Select id="Career" name="Career" defaultValue={""} required>
            <option value="" disabled>
              Career Level
            </option>
            {careers.map((career, index) => (
              <option value={career.Id} key={"career" + index}>
                {career.Value}
              </option>
            ))}
          </Select>
          <Select id="Education" name="Education" defaultValue={""} required>
            <option value="" disabled>
              Education Level
            </option>
            {education.map((level, index) => (
              <option value={level.Id} key={"education" + index}>
                {level.Value}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text>Career Start</InputGroup.Text>
          <FormControl
            id="CareerStart"
            type="date"
            name="CareerStart"
            placeholder="What year did you start your career on?"
            required
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text>Curriculum Vitae (CV)</InputGroup.Text>
          <FileInput
            id="CV"
            name="CV"
            placeholder="CV"
            fileId={profile?.CV ?? ""}
            accept="*"
            hasFile={
              profile !== undefined &&
              profile?.CV !== "" &&
              profile?.CV !== "00000000-0000-0000-0000-000000000000"
            }
            onChange={(event) => {
              const values = event.currentTarget.value.split("\\")
              const name =
                values.length == 1 ? "Choose CV File" : values[values.length - 1]
              setFileNames({ ...fileNames, cv: name })
            }}
            formGroup={false}
            required={!isEditing}
          />
        </InputGroup>
        <div className="display-6 mb-4 text-bold">Account</div>
        <p>Enter a password not less than 6 characters long.</p>
        <InputGroup className="mb-3">
          <FormControl
            id="Password"
            type="password"
            name="Password"
            placeholder="Password"
            autoComplete="new-password"
            required={!isEditing}
          />
          <FormControl
            id="ConfirmPassword"
            type="password"
            name="ConfirmPassword"
            placeholder="Confirm Password"
            autoComplete="new-password"
            required={!isEditing}
          />
        </InputGroup>
        <InputGroup>
          {isEditing
            ?
            (<>
              <FormControl type="hidden" name="IsUpdating" value="true" />
              <FormControl type="hidden" name="UserId" value={userId} />
              <Button type="submit" className="mb-4">
                Update
              </Button>
            </>)
            :
            (<Button type="submit" className="mb-4">
              Register
            </Button>)}
        </InputGroup>
      </Form>
    </>
  )
}

export default Individual
