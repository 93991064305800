import * as React from 'react'
import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { ArrowClockwise } from 'react-bootstrap-icons'
import NewsfeedComponent from '../components/Newsfeed/Newsfeed'
import Spinner from '../components/Spinner/Spinner'
import Toolbar from '../components/Toolbar/Toolbar'

type Article = {
  Id: number
  Title: string
  Content: string
  CreatedOn: string
  CreatedBy: string
  UpdatedOn: string
  UpdatedBy: string
  Visible: boolean
  IsDeleted: boolean
}

const NewsFeed: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState<Article[]>([])

  const getArticles = async () => {
    const [articlesData] = await Promise.all([
      fetch(`/api/article/publicarticles`).then((response) => response.json()),
    ])
    setArticles(articlesData)
    setLoading(false)
  }
  useEffect(() => { getArticles() }, [])

  const reload = async () => {
    setLoading(true)
    await getArticles()
    setLoading(false)
  }

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Newsfeed</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
        </Toolbar>
      </div>
      {articles.map((article, index) => (
        <NewsfeedComponent
          key={`article-${article.Id ?? index}`}
          title={article.Title}
          date={new Date(article.UpdatedOn)}
          body={article.Content}
          classes="border-top"
          id={article.Id}
        />
      ))}
    </>
  )
}

export default NewsFeed
