import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import { Subscriber } from '../../types/subscriber'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { subscribersTableColumns } from '../../data/SubscribersTableColumns'
import { ArrowClockwise } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import Toolbar from '../../components/Toolbar/Toolbar'

type SubscribersProps = {}

const Subscribers = ({ }: SubscribersProps) => {
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState(0)
  const [loading, setLoading] = useState(true)
  const [subscribers, setSubscribers] = useState<Array<Subscriber>>([])
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getSubscribers = async () => {
    const [subscribersData]: [Array<Subscriber>] = await Promise.all([
      fetch(`/api/subscriber/list`).then(response => response.json()),
    ])
    setSubscribers(subscribersData)
    setLoading(false)
  }
  useEffect(() => { getSubscribers() }, [])

  const reload = async () => {
    setLoading(true)
    await getSubscribers()
    setLoading(false)
  }

  const deleteSubscriber = (event: React.MouseEvent<HTMLButtonElement>) => {
    setId(Number(event.currentTarget.getAttribute('data-id')))
    setShowModal(true)
  }
  const onDeleteSubscriber = async () => {
    setShowModal(false)
    setLoading(true)
    const rawResponse = await fetch(`/api/subscriber/delete?id=${id}`, { method: 'DELETE' })
    const content = await rawResponse.json()
    setToastMessage(`${content.type === 'SUCCESS'
      ? 'Subscriber deactivated 🚩'
      : 'Something went wrong ❌'}`)
    setShowToast(true)
    setLoading(false)
  }

  const columns = React.useMemo<ColumnDef<Subscriber>[]>(
    () => subscribersTableColumns({ deleteSubscriber }), [])
  const table = useReactTable({
    data: subscribers,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Subscribers</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
        </Toolbar>
      </div>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Subscriber
            <span className="fw-bold text-primary">#{id}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the subscriber with Id{" "}
          <span className="fw-bold text-primary">#{id}</span>? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeleteSubscriber}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Subscribers
