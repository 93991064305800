import * as React from 'react'
import { Link } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import { Applicant } from '../types/applicant'
import { ColumnDef } from '@tanstack/react-table'
import { stringFilter } from '../utils/filters'
import { Status } from '../types/status'
import { Button } from 'react-bootstrap'
import { ClockHistory, PersonCircle } from 'react-bootstrap-icons'

const STATUS = {
  'null': 'Pending',
  'true': 'Approved',
  'false': 'Rejected',
}

const STATUS_COLOR = {
  'null': 'primary',
  'true': 'success',
  'false': 'secondary',
}

type AllApplicantsTableColumnsProps = {
  onStatusChange: React.ChangeEventHandler<HTMLSelectElement>,
  onDetailsChange: React.ChangeEventHandler<HTMLInputElement>,
  onScoreChange: React.ChangeEventHandler<HTMLInputElement>,
}

const allApplicantsTableColumns = ({ onStatusChange, onDetailsChange, onScoreChange }: AllApplicantsTableColumnsProps): ColumnDef<Applicant>[] => [
  {
    header: () => <span>Id</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Id',
    cell: info => info.getValue(),
    filterFn: stringFilter,
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>First Name</span>,
    accessorKey: 'FirstNameEn',
    accessorFn: row => row.FirstNameEn,
    id: 'FirstNameEn',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Middle Name</span>,
    accessorKey: 'MiddleNameEn',
    accessorFn: row => row.MiddleNameEn,
    id: 'MiddleNameEn',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Last Name</span>,
    accessorKey: 'LastNameEn',
    accessorFn: row => row.LastNameEn,
    id: 'LastNameEn',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Organization (English)</span>,
    accessorKey: 'OrganizationNameEn',
    accessorFn: row => row.OrganizationNameEn,
    id: 'OrganizationNameEn',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Organization (Arabic)</span>,
    accessorKey: 'OrganizationNameAr',
    accessorFn: row => row.OrganizationNameAr,
    id: 'OrganizationNameAr',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>HR Approval</span>,
    accessorKey: 'Approved',
    accessorFn: row => row.Approved,
    id: 'Approved',
    cell: info => {
      const HRApproved = info.getValue()
      return <Badge bg={`${STATUS_COLOR[HRApproved as keyof typeof STATUS_COLOR]}`}>
        {`${STATUS[HRApproved as keyof typeof STATUS]}`}
      </Badge>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue(columnId)
      const statusString = STATUS[value as keyof typeof STATUS].toLowerCase()
      return Boolean(statusString.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = STATUS[current.original.Approved as keyof typeof STATUS].toLowerCase()
      const nextStatus = STATUS[next.original.Approved as keyof typeof STATUS].toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Application Status</span>,
    accessorKey: 'Status',
    accessorFn: row => ({ Id: row.Id, Status: row.Status }),
    id: 'Status',
    cell: info => {
      const { Id, Status } = info.getValue<{ Id: String, Status: Status }>()
      return <Form.Select
        onChange={onStatusChange}
        defaultValue={Status}
        data-id={`${Id}`}
      >
        <option value="Pending">Pending</option>
        <option value="Accepted">Accepted</option>
        <option value="Rejected">Rejected</option>
        <option value="Withdrawn">Withdrawn</option>
      </Form.Select>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue<any>(columnId).Status.toLowerCase()
      return Boolean(value.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = current.original.Status.toLowerCase()
      const nextStatus = next.original.Status.toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Details</span>,
    accessorKey: 'Details',
    accessorFn: row => ({ Id: row.Id, Details: row.Details }),
    id: 'Details',
    cell: info => {
      const { Id, Details } = info.getValue<{ Id: String, Details: string }>()
      return <Form.Control
        onChange={onDetailsChange}
        defaultValue={Details}
        as="textarea"
        aria-label="details"
        data-id={`${Id}`}
      />
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = (row.getValue<any>(columnId).Details ?? '').toLowerCase()
      return Boolean(value.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentDetails = (current.original.Details ?? '').toLowerCase()
      const nextDetails = (next.original.Details ?? '').toLowerCase()
      return currentDetails === nextDetails
        ? 0
        : currentDetails > nextDetails
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Score</span>,
    accessorKey: 'Score',
    accessorFn: row => ({ Id: row.Id, Score: row.Score }),
    id: 'Score',
    cell: info => {
      const { Id, Score } = info.getValue<{ Id: String, Score: string }>()
      return <Form.Control
        type="number"
        aria-label="score"
        onChange={onScoreChange}
        defaultValue={Score ?? ""}
        data-id={`${Id}`}
      />
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = `${(row.getValue<any>(columnId).Score ?? '')}`.toLowerCase()
      return Boolean(value.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentScore = `${(current.original.Score ?? '')}`.toLowerCase()
      const nextScore = `${(next.original.Score ?? '')}`.toLowerCase()
      return currentScore === nextScore
        ? 0
        : currentScore > nextScore
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Actions</span>,
    accessorKey: 'User',
    accessorFn: row => row.User,
    id: 'User',
    cell: info => {
      const userId = info.getValue()
      return (
        <div className="text-nowrap">
          <Link to={`/profile/${userId}`}>
            <Button
              className="p-0 p-2 btn-action border-0 bg-transparent"
              data-id={userId}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Profile"
            >
              <PersonCircle size={25} />
            </Button>
          </Link>
          <Link to={`/applications/${userId}`}>
            <Button
              className="p-0 p-2 btn-action border-0 bg-transparent"
              data-id={userId}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Applications"
            >
              <ClockHistory size={25} />
            </Button>
          </Link>
        </div>
      )
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  STATUS,
  STATUS_COLOR,
  allApplicantsTableColumns,
}