import React from 'react'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import { Article } from '../../types/article'
import { ListTask, Plus } from 'react-bootstrap-icons'
import { submitForm } from '../../utils/forms'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { articlesTableColumns } from '../../data/ArticlesTableColumns'
import { ArrowClockwise } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import Toolbar from '../../components/Toolbar/Toolbar'

type NewsfeedListProps = {}
type response = {
  type: string
  message: string
  id: number
}
type articleResponse = {
  type: string
  message: string
  model: Article
}
const NewsfeedList = ({ }: NewsfeedListProps) => {
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState(0)
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState<Array<Article>>([])
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])
  const navigate = useNavigate()

  const getArticles = async () => {
    const [articlesData] = await Promise.all([
      fetch(`/api/article/articles`).then(response => response.json()),
    ])
    setArticles(articlesData)
    setLoading(false)
  }
  useEffect(() => { getArticles() }, [])

  const reload = async () => {
    setLoading(true)
    await getArticles()
    setLoading(false)
  }

  const editArticle = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = Number(event.currentTarget.getAttribute('data-id'))
    setId(selectedId)
    navigate(`/admin/article-info/${selectedId}`)
  }
  const duplicateArticle = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true)
    const selectedId = Number(event.currentTarget.getAttribute('data-id'))
    const content = await submitForm(`/api/article/duplicate`, { id: selectedId })
    setToastMessage(`${content.type === 'SUCCESS'
      ? 'Article Duplicated ✅'
      : 'Something went wrong ❌'}`)
    getArticles()
    setShowToast(true)
    setLoading(false)
  }
  const deleteArticle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setId(Number(event.currentTarget.getAttribute('data-id')))
    setShowModal(true)
  }
  const onDeleteArticle = async () => {
    setShowModal(false)
    setLoading(true)
    const rawResponse = await fetch(`/api/article/delete?id=${id}`, { method: 'DELETE' })
    const content: response = await rawResponse.json()
    setToastMessage(`${content.type === 'SUCCESS'
      ? 'Article Deleted 🚩'
      : 'Something went wrong ❌'}`)
    setShowToast(true)
    setLoading(false)
  }

  const columns = React.useMemo<ColumnDef<Article>[]>(
    () => articlesTableColumns({ editArticle, duplicateArticle, deleteArticle }), [])
  const table = useReactTable({
    data: articles,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Newsfeed</h1>
        <Toolbar>
          {/* <div className="text-nowrap bg-primary d-flex justify-content-around"> */}
          <Button onClick={reload} title="Reload">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Link
            className="btn btn-primary"
            to="/admin/subscribers"
            title="Subscribers"
          >
            <ListTask />
            <div><small>Subscribers</small></div>
          </Link>
          <Link
            className="btn btn-primary"
            to="/admin/article-info/new"
            title="Add"
          >
            <Plus size={22} />
            <div><small>Add</small></div>
          </Link>
          {/* </div> */}
        </Toolbar>
      </div>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Article <span className="fw-bold text-primary">#{id}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the article with Id{" "}
          <span className="fw-bold text-primary">#{id}</span>? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeleteArticle}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewsfeedList
