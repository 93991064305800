//React
import * as React from "react";
import { useState } from "react";
//Bootstrap
import Group from "react-bootstrap/FormGroup";
import Control from "react-bootstrap/FormControl";
import Label from "react-bootstrap/FormLabel";
//Props
import { ShortAnswerProps } from "../Props";
const ShortAnswer = ({ question, answer, id }: ShortAnswerProps) => {
  const [userAnswer, setUserAnswer] = useState<string>(answer);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAnswer(event.target.value);
  };
  return (
    <Group className={`py-3`}>
      <Label className="fs-4">
        {" "}
        Q{id + 1}) {question}
      </Label>
      <Control
        as="textarea"
        rows={2}
        maxLength={300}
        value={userAnswer}
        onChange={handleChange}
      />
    </Group>
  );
};

export default ShortAnswer;
