import './Hero.css'
import * as React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import Video from './Video'

const Hero: React.FC = () => {
  return (
    <div className="d-flex">
      <div className="hero row align-items-center gap-4">
        <div className="d-flex flex-wrap align-items-center gap-4">
          <div
            className="col-12 col-lg-5 d-flex align-content-stretch justify-content-center align-items-center flex-column"
          >
            <h1 className="display-1">Welcome to KFAS Learn</h1>
            <p>
              Investing in human capital is at the core of KFAS's mission, hence, we
              provide a number of capacity-building training programs for the
              private sector locally and internationally. These programs aim to
              promote a culture of innovation in business.
            </p>
            <div className="w-100">
              <Link to="/register" className="me-1">
                <Button size="lg">
                  Register
                </Button>
              </Link>
              <Link to="/login">
                <Button variant="outline-primary" size="lg">
                  Login
                </Button>
              </Link>
            </div>
          </div>
          <div className="col w-100">
            <Video />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
