import './ColumnFilter.css'
import * as React from 'react'
import { Column, Table } from '@tanstack/react-table';
import { FormControl } from 'react-bootstrap'

type ColumnFilterProps = {
  column: Column<any, any>
  table: Table<any>
}

function ColumnFilter({ column, table }: ColumnFilterProps) {
  return <FormControl
    type="text"
    value={(column.getFilterValue() ?? '') as string}
    onChange={e => column.setFilterValue(e.target.value)}
    placeholder={`Filter`}
  />
}

export default ColumnFilter;