import * as React from 'react'
import Badge from 'react-bootstrap/Badge'
import { Link } from 'react-router-dom'
import EditorComponent from '../../components/TextEditor/EditorComponent'
import { NewsfeedProps } from './Props'

const Newsfeed = ({ title, date, body, classes, id }: NewsfeedProps) => {
  return (
    <div className={`py-1 ${classes}`}>
      <Badge>{date.toDateString()}</Badge>
      <div className="display-6">{title}</div>
      <div>
        {/* {bodyText.text.substring(0, 100)} */}
        <EditorComponent
          id={id}
          initialState={body}
          setEditorDataState={() => { }}
          toolbarHidden={true}
          wrapperClassName="article"
          readOnly={true}
        />
      </div>
      <Link to={`/newsfeed/${id}`}>More ({title})</Link>
    </div>
  )
}

export default Newsfeed
