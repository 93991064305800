//React
import React from "react";
import { useState, useEffect } from "react";
//Bootstrap
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
//Filter
import Filter from "../../components/Filter/Filter";
const filters = [
  {
    category: "Classification",
    options: ["OE", "AEE", "LEE", "Custom"],
  },
  {
    category: "Ongoing Event",
    options: ["KFAS-1", "KFAS-2"],
  },
  { category: "Name", options: ["John Doe", "Mary Jane"] },
];
type Props = {};
type Applicant = {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  attended: string;
  date: Date;
};
const Attendance = (props: Props) => {
  //hooks
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  useEffect(() => {
    //dummy data for applicants
    const generatedApplicants: Applicant[] = [];
    for (let i = 1; i <= 20; i++) {
      const applicant: Applicant = {
        id: i,
        firstName: `John`,
        lastName: `Doe`,
        company: "xyz",
        date: new Date(2022, 1, 1),
        attended: "Fully Attended",
      };
      generatedApplicants.push(applicant);
    }
    setApplicants(generatedApplicants);
  }, []);
  //Handlers
  const updateChanges = () => {
    setToastMessage("Changes Updated✅");
    setShowToast(true);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative">
        <h1 className="display-6 mb-4 text-primary">Attendance - Event Name</h1>
        <div>
          {/* <Filter filters={filters} /> */}
          <Button onClick={updateChanges}>Update Changes</Button>
        </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Company</th>
            <th>Date</th>
            <th>Attendance</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {applicants.map((applicant) => (
            <tr key={`applicant-${applicant.id}`}>
              <td>{applicant.id}</td>
              <td>{applicant.firstName}</td>
              <td>{applicant.lastName}</td>
              <td>{applicant.company}</td>
              <td>{applicant.date.toDateString()}</td>
              <td>
                <Form.Select aria-label="status">
                  <option value="pending">Fully Attended</option>
                  <option value="accepted">Partially Attended</option>
                  <option value="rejected">Did not Attend</option>
                </Form.Select>
              </td>
              <td>
                <Form.Group controlId="attendance">
                  <FormControl as="textarea" aria-label="details" />
                </Form.Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default Attendance;
