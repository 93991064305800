import { FilterFn } from '@tanstack/table-core'
const dateFilter: FilterFn<any> = (row, columnId, filterValue) => {
  const search = filterValue.toLowerCase()
  const dateString = new Date(row.getValue<string>(columnId)).toLocaleDateString("en-GB")
  return Boolean(dateString.includes(search))
}

const stringFilter: FilterFn<any> = (row, columnId, filterValue) => {
  const search: string = filterValue.toLowerCase()
  const value: string = `${(row.getValue(columnId) ?? '')}`.toLowerCase()
  return Boolean(value.includes(search))
}

export {
  dateFilter,
  stringFilter,
}