import * as React from 'react'
import { Link } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import { Event } from '../types/event'
import { dateFilter, stringFilter } from '../utils/filters'
import { Badge, Button } from 'react-bootstrap'
import { People, Files, PencilSquare, Trash, FileRichtext } from 'react-bootstrap-icons'

const STATUS_ACTIVE = {
  'true': 'Active',
  'false': 'Inactive',
}

type EventsTableColumnsProps = {
  editEvent: React.MouseEventHandler<HTMLButtonElement>
  duplicateEvent: React.MouseEventHandler<HTMLButtonElement>
  deleteEvent: React.MouseEventHandler<HTMLButtonElement>
}

const eventsTableColumns = ({ editEvent, duplicateEvent, deleteEvent }: EventsTableColumnsProps): ColumnDef<Event>[] => [
  {
    header: () => <span>Id</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Id',
    cell: info => info.getValue(),
    filterFn: stringFilter,
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>Type</span>,
    accessorKey: 'Type',
    accessorFn: row => row.Type,
    id: 'Type',
    cell: info => {
      const type = info.getValue<string>()
      return <Badge bg="secondary" className="w-100">{type}</Badge>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const type = row.getValue<string>(columnId)
      return Boolean(type.toLowerCase().includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentType = current.original.Type.toLowerCase()
      const nextType = next.original.Type.toLowerCase()
      return currentType === nextType
        ? 0
        : currentType > nextType
          ? 1
          : -1
    }
  },
  {
    header: () => <span>Title</span>,
    accessorKey: 'Title',
    accessorFn: row => ({ EventId: row.Id, EventName: row.Title }),
    id: 'Title',
    cell: info => {
      const { EventId, EventName } = info.getValue<{ EventId: number, EventName: string }>()
      return <Link to={`/admin/event-info/${EventId}/applicant-dashboard`}>{EventName}</Link>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const { EventName } = row.getValue<{ EventId: number, EventName: string }>(columnId)
      return Boolean(EventName.toLowerCase().includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentTitle = current.original.Title.toLowerCase()
      const nextTitle = next.original.Title.toLowerCase()
      return currentTitle === nextTitle
        ? 0
        : currentTitle > nextTitle
          ? 1
          : -1
    },
  },
  {
    header: () => <span>Provider</span>,
    accessorKey: 'Provider',
    accessorFn: row => row.Provider,
    id: 'Provider',
    cell: info => {
      const provider = info.getValue<string>()
      return <Badge bg="primary" className="w-100">{provider}</Badge>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const provider = row.getValue<string>(columnId)
      return Boolean(provider.toLowerCase().includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentProvider = current.original.Provider.toLowerCase()
      const nextProvider = next.original.Provider.toLowerCase()
      return currentProvider === nextProvider
        ? 0
        : currentProvider > nextProvider
          ? 1
          : -1
    }
  },
  {
    header: () => <span>From</span>,
    accessorKey: 'FromDate',
    accessorFn: row => row.FromDate,
    id: 'FromDate',
    cell: info => new Date(info.getValue<string>()).toLocaleDateString('en-GB'),
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>To</span>,
    accessorKey: 'ToDate',
    accessorFn: row => row.ToDate,
    id: 'ToDate',
    cell: info => new Date(info.getValue<string>()).toLocaleDateString('en-GB'),
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>Active</span>,
    accessorKey: 'Active',
    accessorFn: row => row.Active,
    id: 'Active',
    cell: info => {
      const isActive = info.getValue()
      return isActive
        ? <Badge bg="success">Active</Badge>
        : <Badge bg="light" className="text-dark">Inactive</Badge>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue<any>(columnId)
      const statusString = STATUS_ACTIVE[value as keyof typeof STATUS_ACTIVE].toLowerCase()
      return Boolean(statusString.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = STATUS_ACTIVE[current.original.Active as keyof typeof STATUS_ACTIVE].toLowerCase()
      const nextStatus = STATUS_ACTIVE[next.original.Active as keyof typeof STATUS_ACTIVE].toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    }
  },
  // {
  //   header: () => <span>Applicants</span>,
  //   accessorKey: 'Id',
  //   accessorFn: row => row.Id,
  //   id: 'Applicants',
  //   cell: info =>
  //     <Link
  //       className="d-block m-auto border-0 text-decoration-underline"
  //       to={`/admin/event-info/${info.getValue()}/applicant-dashboard`}
  //     >
  //       Applicants
  //     </Link>,
  //   enableSorting: false,
  //   enableColumnFilter: false,
  // },
  {
    header: () => <span>Actions</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Actions',
    cell: info => {
      const eventId = info.getValue()
      return (
        <div className="text-nowrap">
          <Link
            to={`/event-list/${eventId}`}
          >
            <Button
              className="p-0 p-2 btn-action border-0 bg-transparent"
              data-id={eventId}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Details"
            >
              <FileRichtext size={25} />
            </Button>
          </Link>
          <Button
            className="p-0 p-2 btn-action border-0 bg-transparent"
            data-id={eventId}
            onClick={duplicateEvent}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Duplicate"
          >
            <Files size={25} />
          </Button>
          <Button
            className="p-0 p-2 btn-action border-0 bg-transparent"
            data-id={eventId}
            onClick={editEvent}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Edit"
          >
            <PencilSquare size={25} />
          </Button>
          <Link
            to={`/admin/event-info/${eventId}/applicant-dashboard`}
          >
            <Button
              className="p-0 p-2 btn-action border-0 bg-transparent"
              data-id={eventId}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Applicants"
            >
              <People size={25} />
            </Button>
          </Link>
          <Button
            className="p-0 p-2 btn-action border-0 bg-transparent"
            data-id={eventId}
            onClick={deleteEvent}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
          >
            <Trash size={25} />
          </Button>
        </div>
      )
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  STATUS_ACTIVE,
  eventsTableColumns,
}