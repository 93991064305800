//React
import React from "react";
//Bootstrap
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
//Bootstrap Icons
import { Printer } from "react-bootstrap-icons";
//Chartjs
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
//CSS
import "./FeedbackReport.css";
//Data
import { questions } from "../../components/Feedback/Questions/Data";
const labels10 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const data10 = [0, 10, 5, 2, 20, 30, 45, 18, 90, 10];
const labels5 = ["1", "2", "3", "4", "5"];
const data5 = [0, 10, 5, 2, 20];
const labels2 = ["Yes", "No"];
const data2 = [25, 10];
//generate random colors for graph
const getRandomColor = () => {
  var letters = "0123456789ABCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
//dummy data
const dataConfig10 = {
  labels: labels10,
  datasets: [
    {
      backgroundColor: data10.map((el) => getRandomColor()),
      data: data10,
    },
  ],
};
const dataConfig5 = {
  labels: labels5,
  datasets: [
    {
      backgroundColor: data5.map((el) => getRandomColor()),
      data: data5,
    },
  ],
};
const dataConfig2 = {
  labels: labels2,
  datasets: [
    {
      backgroundColor: data2.map((el) => getRandomColor()),
      data: data2,
    },
  ],
};
const shortAnswers = [
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet mi id nisi ultricies tincidunt. Phasellus gravida, risus quis scelerisque dapibus, lectus velit varius ex, a molestie nibh quam id libero.",
    email: "example@email.com",
  },
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet mi id nisi ultricies tincidunt.",
    email: "example@email.com",
  },
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    email: "example@email.com",
  },
];
//graph options
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Participants",
      },
    },
    x: {
      title: {
        display: true,
        text: "scale",
      },
    },
  },
};
const FeedbackReport = () => {
  //handlers
  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="display-6 mb-4 text-primary">Feedback Report</h1>
        <Button variant="outline-primary" onClick={handlePrint}>
          <Printer /> Print
        </Button>
      </div>
      <div>
        {questions.map((question) => {
          if (
            question.questionType == "scale" &&
            question.scale?.points.length == 10
          ) {
            return (
              <div className="question" key={`question-${question.id}`}>
                <p className="fs-4">
                  Q{question.id}) {question.question}
                </p>
                <div className="m-auto" style={{ width: "40em" }}>
                  <Bar data={dataConfig10} options={options} />
                </div>
                <hr />
              </div>
            );
          } else if (
            question.questionType == "scale" &&
            question.scale?.points.length == 5
          ) {
            return (
              <div className="question" key={`question-${question.id}`}>
                <p className="fs-4">
                  Q{question.id}) {question.question}
                </p>
                <div className="m-auto" style={{ width: "40em" }}>
                  <Bar data={dataConfig5} options={options} />
                </div>
                <hr />
              </div>
            );
          } else if (question.questionType == "shortAnswer") {
            return (
              <div className="question" key={`question-${question.id}`}>
                <p className="fs-4">
                  Q{question.id}) {question.question}
                </p>
                <Table striped bordered hover responsive>
                  <tbody>
                    {shortAnswers.map((answer) => (
                      <tr>
                        <td>{answer.text}</td>
                        <td className="email">{answer.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <hr />
              </div>
            );
          } else if (
            question.questionType == "scale" &&
            question.scale?.points.length == 2
          ) {
            return (
              <div className="question" key={`question-${question.id}`}>
                <p className="fs-4">
                  Q{question.id}) {question.question}
                </p>
                <div className="m-auto" style={{ width: "40em" }}>
                  <Bar data={dataConfig2} options={options} />
                </div>
                <hr />
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default FeedbackReport;
