import * as React from 'react'
import { useEffect, useState, createRef } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import data from '../shared/Data.en.json'
import ObjectDisplay from './ObjectDisplay'

interface ApplicationData {
  [key: string]: string | number
}
type Props = {}
type ObjectKey = keyof typeof data

const EmployeeApplication = (props: Props) => {
  const { id: AppId } = useParams()
  const navigate = useNavigate()
  const [applicationData, setApplicationData] = useState<ApplicationData>({})
  const [loading, setloading] = useState(true)
  const [alert, setAlert] = useState<string>('')

  useEffect(() => {
    const loadData = async () => {
      const [appData] = await Promise.all([
        fetch(`/api/data/AppData?AppId=${AppId}`)
          .then((response) => response.json())
      ])
      setApplicationData(appData)
      if (appData) setloading(false)
      else navigate(-1)
    }
    loadData()
  }, [AppId])
  
  if (loading) return <Spinner />
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Employee Application</h1>
      {alert.length > 0 && (
        <div
          className="alert alert-danger"
          role="alert"
          dangerouslySetInnerHTML={{ __html: alert }}
        />
      )}
      {/**DISPLAY DATA */}
      <ObjectDisplay data={applicationData} />
      {/* {Object.entries(applicationData).map(([key,value])=>(
        <div key={key}>
            <h3>{key.split(/(?=[A-Z])/).join(' ')}:</h3>
            {typeof value === 'string' && isGUID(value)?(
                
                <a href={`/api/data/AppFile/?id=${value}`} download><p>Download</p></a>
            ):(
                <div> {typeof value === 'object'?(<p>{}</p>):(<p>{value}</p>)}</div>
            )}
        </div>
      ))} */}
    </>
  )
}

function isGUID(value: string): boolean {
  const guidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/
  return guidPattern.test(value)
}

export default EmployeeApplication
