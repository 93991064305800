import './Toolbar.css'
import React, { useState } from 'react'
import { LayoutSidebar } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import Sidebar from '../Sidebar/Sidebar'
import RequireLogin from '../../guards/RequireLogin';

type ToolbarProps = {
  children: React.ReactNode
}

const Toolbar = ({ children }: ToolbarProps) => {
  const pathName = window.location.pathname
  const isPublicPage =
    pathName !== '/event-list' &&
    pathName !== '/newsfeed'
  const [show, setShow] = useState(false)

  return (
    <>
      <div className="toolbar text-nowrap bg-primary d-flex justify-content-around">
        <RequireLogin>
          <Button onClick={() => setShow(true)} title="Menu">
            <LayoutSidebar />
            <div><small>Menu</small></div>
          </Button>
        </RequireLogin>
        {children}
      </div>
      <RequireLogin>
        <Sidebar show={show} onHide={() => setShow(false)} />
      </RequireLogin>
    </>
  )
}

export default Toolbar