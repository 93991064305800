import './BackgroundCanvas.css'
import * as React from 'react'
import { isWithinCircle } from '../utils/math'
import { Particle, animate } from '../utils/particle'

type BackgroundCanvasProps = {
  enabled: boolean
}

const BackgroundCanvas = ({ enabled }: BackgroundCanvasProps) => {
  if (!enabled) { return <></> }
  React.useEffect(() => {
    const canvas = document.getElementById('backgroundCanvas') as HTMLCanvasElement
    const ctx = canvas.getContext('2d')
    if (!ctx) { return }
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    const resizeHandler = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }
    window.addEventListener('resize', resizeHandler)

    const particlesArray: Array<Particle> = []
    const radius = 2 ** 6
    const particleInstances = 3
    const hslColor = { hue: 20, saturation: 85, lightness: 85 }
    const mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    const mounseMoveHandler = (event: MouseEvent) => {
      if (isWithinCircle(mouse.x, mouse.y, radius, event.x, event.y)) { return }
      mouse.x = event.x
      mouse.y = event.y
      for (let i = 0; i < particleInstances; i++) {
        particlesArray.push(new Particle(mouse, hslColor))
      }
    }
    window.addEventListener('mousemove', mounseMoveHandler)

    const particalSpacing = 128
    const horizontalParticles = Math.floor(window.innerWidth / particalSpacing)
    const verticalParticles = Math.floor(window.innerHeight / particalSpacing)
    for (let i = 0; i < horizontalParticles; i++) {
      for (let j = 0; j < verticalParticles; j++) {
        particlesArray.push(new Particle({
          x: i * (particalSpacing + ((Math.random() * particalSpacing / 2) * (Math.random() * 2 < 1 ? -1 : 1))),
          y: j * (particalSpacing + ((Math.random() * particalSpacing / 2) * (Math.random() * 2 < 1 ? -1 : 1)))
        }, hslColor))
      }
    }

    const lineDrawCutOffDistance = 2 ** 7
    const maxParticles = 150
    animate(ctx, particlesArray, lineDrawCutOffDistance, maxParticles)

    // On unmount cleanup
    return () => {
      window.removeEventListener('resize', resizeHandler)
      window.removeEventListener('mousemove', mounseMoveHandler)
    }
  }, [])
  return (<><canvas id="backgroundCanvas"></canvas></>)
}

export default BackgroundCanvas