//React
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { Button, Form, FormControl, InputGroup } from "react-bootstrap"
import Spinner from "../components/Spinner/Spinner"

type Props = {}
const ResetPassword = (props: Props) => {
  const { id: userId, code } = useParams()
  const [loading, setloading] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setloading(true)
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    try {
      const xsrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN='))
        ?.split('=')[1] ?? ''
      const headers = {
        'X-XSRF-TOKEN': xsrfToken,
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
      }
      const body = JSON.stringify(Object.fromEntries(formData))
      const response = await fetch(`/api/account/resetpassword`, { method: 'POST', body, headers, })
      const json = await response.json()
      navigate(`/login`);
    } catch (e) {

    }
    setloading(false)
  }
  if (loading) return (<Spinner />)
  return (
    <div className="p-2 row">
      <h1 className="display-6 mb-4 text-primary">Reset Password</h1>
      <Form
        method="POST"
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          <Form.Label>Enter a password not less than 6 characters long.</Form.Label>
          <FormControl
            id="Password"
            type="password"
            name="Password"
            placeholder="Password"
            autoComplete="new-password"
            required
          />
          <InputGroup className="mb-3">
          </InputGroup>
          <FormControl
            id="ConfirmPassword"
            type="password"
            name="ConfirmPassword"
            placeholder="Confirm Password"
            autoComplete="new-password"
            required
          />
          <FormControl
            type="hidden"
            name="UserId"
            value={userId}
          />
          <FormControl
            type="hidden"
            name="Code"
            value={code}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default ResetPassword
