const downloadCSVFile = (csv: string, filename: string) => {
  const anchor = document.createElement('a')
  anchor.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`)
  anchor.setAttribute('download', `${filename}.csv`)
  anchor.style.display = 'none'
  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
}

// write a function to handle commas and quotation marks in csv
const escapeData = (data: string) => {
  return data.replace(/"/g, '""')
}

export {
  downloadCSVFile,
  escapeData
}