import React from 'react'
import { useAppSelector } from '../store/hooks'

type RequireLogoutProps = {
  children: React.ReactNode
}

const RequireLogout = ({ children }: RequireLogoutProps) => {
  const state = useAppSelector(selector => selector.auth.state)
  if (state !== 'LOGGED_IN') {
    return <>{children}</>
  }
  return <></>
}

export default RequireLogout
