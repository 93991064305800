//React
import React from "react";
import { useEffect } from "react";

//Bootstrap
import ListGroup from "react-bootstrap/ListGroup";

//Data
import data from "./shared/Data.en.json";

type Props = {};
type ObjectKey = keyof typeof data;
const TermsAndConditions = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Terms &amp; Conditions</h1>
      <p className="mb-4">
        {data.termsAndConditions}
      </p>
      {Object.keys(data).map((key) => {
        const dataKey = key as ObjectKey;
        return (
          <div key={key} className="mb-4">
            <h4 className="mb-4 text-primary text-capitalize">{key}</h4>
            <ListGroup>
              {data[dataKey].map((text, i) => (
                <ListGroup.Item key={`${key}-${i}`}>{text}</ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        );
      })}
    </>
  );
};

export default TermsAndConditions;
