import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from '../../components/Spinner/Spinner'
import { downloadCSVFile, escapeData } from '../../utils/csv'
import { useParams } from 'react-router'
import { submitForm } from '../../utils/forms'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { acceptedApplicantsTableColumns, STATUS_ATTENDANCE } from '../../data/AcceptedApplicantsTableColumns'
import { ArrowClockwise, Download, Save } from 'react-bootstrap-icons'
import Table from '../../components/Table/Table'
import { AcceptedApplicant } from '../../types/applicant'
import Toolbar from '../../components/Toolbar/Toolbar'
import { ACCEPTED_APPLICANT, APPLICANTS_ATTENDANCE, APPLICANTS_FEEDBACK, DOWNLOAD, EVENT_TITLE } from '../../API'

type AcceptedApplicantsProps = {}
type ApplicantAttendance = {
  Id: number
  Attendance: string
}
type ApplicantFeedback = {
  Id: number
  Feedback: boolean
}

const AcceptedApplicants = ({ }: AcceptedApplicantsProps) => {
  const [loading, setLoading] = useState(true)
  const [applicantAttendance, setApplicantAttendance] = useState<Array<ApplicantAttendance>>([])
  const [applicantFeedback, setApplicantFeedback] = useState<Array<ApplicantFeedback>>([])
  const { id: eventId } = useParams()
  const [eventName, setEventName] = useState('')
  const [applicants, setApplicants] = useState<Array<AcceptedApplicant>>([])
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])

  const getApplicants = async () => {
    const [applicationsData, eventNameData] = await Promise.all([
      fetch(ACCEPTED_APPLICANT`${eventId ?? ''}`)
        .then(response => response.json()),
      fetch(EVENT_TITLE`${eventId ?? ''}`)
        .then(response => response.text()),
    ])
    setEventName(eventNameData)
    setApplicants(applicationsData)
    setLoading(false)
  }
  useEffect(() => { getApplicants() }, [])

  const reload = async () => {
    setLoading(true)
    await getApplicants()
    setLoading(false)
  }

  const onAttendanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.currentTarget.dataset.id)
    const value = event.currentTarget.value
    setApplicantAttendance(currentAttendance => {
      const attendanceExists = currentAttendance
        .find(attendance => attendance.Id === selectedId)
      const updatedAttendances = attendanceExists
        ? currentAttendance
          .map(attendance => {
            if (attendance.Id === selectedId)
              attendance.Attendance = value
            return attendance
          })
        : [...currentAttendance, { Id: selectedId, Attendance: value }]
      return updatedAttendances
    })
  }
  const onFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = Number(event.currentTarget.dataset.id)
    const value = event.currentTarget.checked
    setApplicantFeedback(currentFeedback => {
      const feedbackExists = currentFeedback
        .find(feedback => feedback.Id === selectedId)
      const updatedFeedbacks = feedbackExists
        ? currentFeedback
          .map(feedback => {
            if (feedback.Id === selectedId)
              feedback.Feedback = value
            return feedback
          })
        : [...currentFeedback, { Id: selectedId, Feedback: value }]
      return updatedFeedbacks
    })
  }

  const columns = React.useMemo<ColumnDef<AcceptedApplicant>[]>(
    () => acceptedApplicantsTableColumns({ onAttendanceChange, onFeedbackChange }), [])
  const table = useReactTable({
    data: applicants,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const generateCSV = () => {
    let lines = ''
    applicants.forEach((applicant, index) => {
      const tuples = Object.entries(applicant)
      if (index === 0) {
        for (const [key, value] of tuples) {
          lines += `"${escapeData(key)}",`
        }
        lines += '\n'
      }
      for (const [key, value] of tuples) {
        switch (key) {
          case 'Attendance':
            lines += `"${escapeData(STATUS_ATTENDANCE[value as keyof typeof STATUS_ATTENDANCE])}",`
            break
          case 'Certificate':
            lines +=
              value !== '00000000-0000-0000-0000-000000000000' && value !== null
                ? `"https://learn.kfas.org.kw${escapeData(DOWNLOAD`${value as string}`)}",`
                : `"Unsubmitted",`
            break
          case 'FeedbackCompletion':
            lines += `"${value ? 'Yes' : 'No'}",`
            break
          default:
            lines += value ? `"${escapeData(value as string)}",` : `"",`
        }
      }
      lines += '\n'
    })
    const today = new Date()
    const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`
    downloadCSVFile(lines, `${date} - ${eventName} - Accepted Applicants`)
    setToastMessage('Download Successful✅')
    setShowToast(true)
  }
  const onUpdate = async () => {
    const [responseAttendance, responseFeedback] = await Promise.all([
      submitForm(APPLICANTS_ATTENDANCE, applicantAttendance),
      submitForm(APPLICANTS_FEEDBACK, applicantFeedback),
    ])
    if (responseAttendance && responseFeedback) {
      setToastMessage('Changes Finalized✅')
      setShowToast(true)
      await reload()
    } else {
      setToastMessage('Something went wrong❌')
      setShowToast(true)
    }
    setLoading(false)
  }

  if (loading) return <Spinner />
  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Accepted Applicants - {eventName}</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Button onClick={generateCSV} title="Download">
            <Download />
            <div><small>Download</small></div>
          </Button>
          <Button onClick={onUpdate}>
            <Save />
            <div><small>Finalize</small></div>
          </Button>
        </Toolbar>
      </div>
      <Alert variant="info">
        Applicants will be able to access their certificate only if they
        submitted the feedback survey.
      </Alert>
      <Table table={table} />
      <Row>
        <Col>
          <Form.Group controlId="preAssessment" className="mb-3">
            <Form.Label>Pre-Assessment</Form.Label>
            <Form.Control type="file" accept=".doc, .docx, .ppt, .pptx, .pdf" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="postAssessment" className="mb-3">
            <Form.Label>Post-Assessment</Form.Label>
            <Form.Control type="file" accept=".doc, .docx, .ppt, .pptx, .pdf" />
          </Form.Group>
        </Col>
      </Row>
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

export default AcceptedApplicants
