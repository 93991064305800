//React
import React from "react";
import { useState, useEffect } from "react";
//React Router
import { Link } from "react-router-dom";
//Bootstrap
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
type Props = {};
type Applicant = {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  overallScore: number;
};
const Scoring = (props: Props) => {
  //hooks
  const [show, setShow] = useState(false);
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [anonymous, setAnonymous] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  useEffect(() => {
    //dummy data for applicants
    const generatedApplicants: Applicant[] = [];
    for (let i = 1; i <= 20; i++) {
      const applicant: Applicant = {
        id: i,
        firstName: "John",
        lastName: "Doe",
        company: "abc",
        overallScore: 0,
      };
      generatedApplicants.push(applicant);
    }
    setApplicants(generatedApplicants);
  }, []);
  //Handlers
  const handleClose = () => setShow(false); //modal
  const handleShow = () => setShow(true); //modal
  const finalizeChanges = () => {
    handleClose();
    setAnonymous(false);
    setToastMessage("Changes Finalized✅");
    setShowToast(true);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative">
        <h1 className="display-6 mb-4 text-primary">Scoring - Event Name</h1>
        <Button onClick={handleShow}>Finalize Changes</Button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            {!anonymous && <th className="text-center">First Name</th>}
            {!anonymous && <th className="text-center">Last Name</th>}
            {!anonymous && <th className="text-center">Company</th>}
            <th className="text-center">Application</th>
            <th className="text-center">Overall Score</th>
          </tr>
        </thead>
        <tbody>
          {applicants.map((applicant) => (
            <tr key={`applicant-${applicant.id}`}>
              <td>{applicant.id}</td>
              {!anonymous && (
                <td className="text-center">{applicant.firstName}</td>
              )}
              {!anonymous && (
                <td className="text-center">{applicant.lastName}</td>
              )}
              {!anonymous && (
                <td className="text-center">{applicant.company}</td>
              )}
              <td className="text-center">
                <Link to={`/admin/application/${applicant.id}`}>
                  Application
                </Link>
              </td>
              <td className="text-center">{applicant.overallScore}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Finalize Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finalize the changes? This action cannot be
          undone. The scoring will no longer be anonymous.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={finalizeChanges}>
            Finalize
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Scoring;
