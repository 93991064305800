import React from 'react'
import { useAppSelector } from '../store/hooks'

type RequireLoginProps = {
  children: React.ReactNode
}

const RequireLogin = ({ children }: RequireLoginProps) => {
  const state = useAppSelector(selector => selector.auth.state)
  if (state === 'LOGGED_IN') {
    return <>{children}</>
  }
  return <></>
}

export default RequireLogin
