import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import { Plus, ArrowClockwise } from 'react-bootstrap-icons'
import { submitForm } from '../../utils/forms'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { eventsTableColumns } from '../../data/EventsTableColumns'
import Table from '../../components/Table/Table'
import { Event } from '../../types/event'
import Toolbar from '../../components/Toolbar/Toolbar'
import { DELETE_EVENT, DUPLICATE_EVENT, EVENTS } from '../../API'

type EventListProps = {}

const EventList = (props: EventListProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState(0)
  const [events, setEvents] = useState<Array<Event>>([])
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])
  const navigate = useNavigate()

  const getEvents = async () => {
    const [eventsData] = await Promise.all([
      fetch(EVENTS).then(response => response.json()),
    ])
    setEvents(eventsData)
    setLoading(false)
  }
  useEffect(() => { getEvents() }, [])

  const reload = async () => {
    setLoading(true)
    await getEvents()
    setLoading(false)
  }

  const deleteEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setId(Number(event.currentTarget.getAttribute('data-id')))
    setShowModal(true)
  }
  const onDeleteEvent = async () => {
    setShowModal(false)
    setLoading(true)
    const rawResponse = await fetch(DELETE_EVENT`${`${id}`}`, { method: 'DELETE' })
    const content: any = await rawResponse.json()
    await getEvents()
    setLoading(false)
    setToastMessage(`${content.type === 'SUCCESS'
      ? 'Event Deleted 🚩'
      : 'Something went wrong ❌'}`)
    setShowToast(true)
  }
  const editEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = Number(event.currentTarget.getAttribute('data-id'))
    setId(selectedId)
    navigate(`/admin/event-info/${selectedId}`)
  }
  const duplicateEvent = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true)
    const selectedId = Number(event.currentTarget.getAttribute("data-id"))
    setId(selectedId)
    const content = await submitForm(DUPLICATE_EVENT, { id: selectedId })
    await getEvents()
    setToastMessage(`${content.type === 'SUCCESS'
      ? 'Event Duplicated ✅'
      : 'Something went wrong ❌'}`)
    setLoading(false)
    setShowToast(true)
  }

  const columns = React.useMemo<ColumnDef<Event>[]>(
    () => eventsTableColumns({ editEvent, duplicateEvent, deleteEvent }), [])
  const table = useReactTable({
    data: events,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })


  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Offerings</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Link
            className="btn btn-primary"
            to="/admin/event-info/new"
            title="Add"
          >
            <Plus size={22} />
            <div><small>Add</small></div>
          </Link>
        </Toolbar>
      </div>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Event <span className="fw-bold text-primary">#{id}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the event with Id{" "}
          <span className="fw-bold text-primary">#{id}</span>? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeleteEvent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EventList
