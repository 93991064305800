import React from 'react'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

type FileInputProps = {
  id?: string
  formLabel?: string
  accept: string
  fileId: string
  name?: string
  hasFile: boolean
  placeholder?: string
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  formGroup?: boolean
}

const FileInput = ({
  formLabel,
  accept,
  fileId: id,
  name,
  hasFile,
  placeholder,
  required = true,
  formGroup = true,
  onChange
}: FileInputProps) => {
  const [fileExists, setFileExists] = useState(hasFile)

  const children =
    <>
      {formLabel && <Form.Label>{formLabel}</Form.Label>}
      <div
        className={`${fileExists ? '' : 'd-none'} form-floating d-flex justify-content-between align-items-center border`}>
        <a href={`/api/data/download?id=${id}`} className="px-2" download>
          Download
        </a>
        <Button
          className="btn btn-secondary"
          onClick={() => setFileExists(false)}
        >
          Remove
        </Button>
      </div>
      <Form.Control
        type="file"
        accept={accept}
        required={fileExists ? false : required}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={`${fileExists ? 'd-none' : ''}`}
      />
    </>

  return formGroup
    ? (
      <Form.Group className="mb-3">
        {children}
      </Form.Group>
    )
    : children
}

export default FileInput
