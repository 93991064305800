//React
import React from "react";
import { useState, useEffect } from "react";
//Editor
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Toolbar options
const options = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "link",
    "history",
  ],
};

const EditorComponent = ({
  initialState,
  id,
  setEditorDataState,
  toolbarHidden = false,
  wrapperClassName = "wrapper-class",
  readOnly = false,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const data = convertToRaw(editorState.getCurrentContent());
    //update data in parent
    setEditorDataState(id, JSON.stringify(data));
  };

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(convertFromRaw(JSON.parse(initialState)))
    );
  }, []);
  return (
    <Editor
      toolbarHidden={toolbarHidden}
      wrapperClassName={wrapperClassName}
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbar={options}
      editorState={editorState}
      onEditorStateChange={updateTextDescription}
      readOnly={readOnly}
    />
  );
};

export default EditorComponent;
