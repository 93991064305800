//Redux
import {
  createSlice,
  PayloadAction,
  ThunkAction,
  AnyAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "./configureStore";

export interface IEvent {
  id: string;
  classification: string;
  // type: string;
  title: string;
  fromDate: Date | null;
  toDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  provider: string;
  deadline: Date | null;
  seats: number;
  instructors: string;
  address: string;
  location: string;
  overview: string;
  audience: string;
  picture: string;
  brochure: string;
  preAssessment: string;
  postAssessment: string;
  createdBy: string;
  createdOn: Date | null;
  updatedBy: string;
  updatedOn: Date | null;
  active?: boolean;
}

// Define a type for the slice state
export interface InitialState {
  events: IEvent[];
  event: IEvent;
  status: string;
  error: string | undefined;
}
// Define the initial state using that type
const initialState: InitialState = {
  events: [],
  event: {
    id: "0",
    classification: "",
    title: "",
    fromDate: null,
    toDate: null,
    startTime: null,
    endTime: null,
    provider: "",
    deadline: null,
    seats: 0,
    instructors: "",
    address: "",
    location: "",
    overview: "",
    audience: "",
    picture: "",
    brochure: "",
    preAssessment: "",
    postAssessment: "",
    createdBy: "",
    createdOn: null,
    updatedBy: "",
    updatedOn: null,
  },
  status: "idle",
  error: "",
};
//automatically generates action creators and action types
export const EventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    addEvent: (state, action: PayloadAction<IEvent>) => {
      state.events.push(action.payload);
    },
    setEvents: (state, action: PayloadAction<IEvent[]>) => {
      state.events = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEvents.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { addEvent, setEvents } = EventsSlice.actions;

export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
  const response = await fetch(`api/events`);
  const data = await response.json();
  return data;
});
export default EventsSlice.reducer;
