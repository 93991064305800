import * as React from "react";
import Badge from "react-bootstrap/Badge";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { Article as ArticleType } from "../../types/article";
import Spinner from "../Spinner/Spinner";
import EditorComponent from "../TextEditor/EditorComponent";

const Article = ({ }) => {
  const { id } = useParams()
  const [loading, setloading] = useState(true);
  const [article, setArticle] = useState<ArticleType>();
  const [date, setDate] = useState<Date>(new Date());
  useEffect(() => {
    const getArticle = async () => {
      const [articleData] = await Promise.all([
        fetch(`/api/article/article?id=${id}`).then((response) => response.json()),
      ]);
      setArticle(articleData);
      setDate(new Date(article ? article.UpdatedOn : date.toISOString()))
      setloading(false);
    };
    getArticle();
  }, []);
  if (loading) return (<Spinner />);
  return (
    <div className={`py-3`}>
      <Badge>{date.toDateString()}</Badge>
      <h3 className="my-4">{article?.Title}</h3>
      <EditorComponent
        initialState={article?.Content}
        setEditorDataState={() => { }}
        toolbarHidden={true}
        id="Overview"
        readOnly={true}
        wrapperClassName=""
      />
    </div>
  );
};

export default Article;
