import * as React from "react"
import { useEffect, useState } from "react"
import { RWebShare } from "react-web-share"
import { useLocation, Link } from "react-router-dom"
import { useParams, useNavigate } from "react-router"
import Button from "react-bootstrap/Button"
import Badge from "react-bootstrap/Badge"
import Spinner from "../components/Spinner/Spinner"
import Table from "react-bootstrap/Table"
import Image from "react-bootstrap/Image"
import { ShareFill } from "react-bootstrap-icons"
import EditorComponent from "../components/TextEditor/EditorComponent"
import { Event } from "../types/event"
import { connect } from "react-redux"
import { PUBLIC_EVENT } from "../API"

const initialEvent: Event = {
  Id: 0,
  Classification: '1',
  Type: '1',
  Criteria: '',
  Venue: '',
  Title: '',
  FromDate: '',
  ToDate: '',
  StartTime: '00:00',
  EndTime: '00:00',
  Provider: '',
  Deadline: '',
  Seats: 0,
  Instructors: '',
  Address: '',
  Location: '',
  Overview: '{}',
  Audience: '{}',
  Picture: '',
  Brochure: '',
  PreAssessment: '',
  PostAssessment: '',
  Data: '{}',
  CreatedBy: '',
  CreatedOn: new Date().toISOString(),
  UpdatedBy: '',
  UpdatedOn: new Date().toISOString(),
  Active: false,
  IsDeleted: false,
}
const EventDetails: React.FC = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [eventData, setEventData] = useState<Event>(initialEvent)
  const [eventDeadline, setEventDeadline] = useState<Date>(new Date())
  const [isEventOpen, setIsEventOpen] = useState<boolean>(false)
  const { id } = useParams()

  useEffect(() => {
    const getEvent = async () => {
      const rawResponse = await fetch(PUBLIC_EVENT`${id ?? ''}`, {
        method: 'GET',
      })
      let content = (await rawResponse.json()) as Event
      setEventData({ ...content })
      setEventDeadline(new Date(`${content.Deadline}+03:00`))
      setIsEventOpen(new Date(`${content.Deadline}+03:00`) > new Date())
      setLoading(false)
    }
    getEvent()
  }, [])
  if (loading) return <Spinner />
  return (
    <div>
      <div className="d-flex flex-column flex-lg-row justify-content-md-between gap-lg-4 border-bottom">
        <div style={{ maxWidth: "350px" }}>
          <h1 className="display-4">{eventData.Title}</h1>
          <div>
            {isEventOpen ? (
              <Badge bg="success">OPEN</Badge>
            ) : (
              <Badge bg="danger">CLOSED</Badge>
            )}
            <Badge bg="primary" className="ms-2">
              {eventData.Classification}
            </Badge>
          </div>
          <Table hover responsive className="mb-4">
            <thead>
              <tr>
                <th></th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dates</td>
                <td>{new Date(eventData.FromDate).toDateString()}</td>
                <td>{new Date(eventData.ToDate).toDateString()}</td>
              </tr>
              <tr>
                <td>Course Timings</td>
                <td>{`${eventData.StartTime.split(":")[0]}:${eventData.StartTime.split(":")[1]}`} (GMT+3)</td>
                <td>{`${eventData.EndTime.split(":")[0]}:${eventData.EndTime.split(":")[1]}`} (GMT+3)</td>
              </tr>
            </tbody>
          </Table>
          <h6 className="my-2">
            Registration Deadline: <span className={`${isEventOpen ? "text-success" : "text-danger"}`}>{eventDeadline.toDateString()} at {eventDeadline.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })} (GMT+3)</span>
          </h6>
          <div className="d-flex justify-content-end gap-2 mt-2 pb-4">
            <RWebShare
              data={{
                title: "Event Name",
                url: location.pathname,
              }}
            >
              <Button
                className="d-flex align-items-center border-0"
                variant="outline-primary"
              >
                <ShareFill />
              </Button>
            </RWebShare>
            <Button
              as="a"
              href={`${eventData.Brochure}`}
              variant="outline-primary"
            >
              e-brochure
            </Button>
            {isEventOpen &&
              eventData.Type != "5" && (
                <Link
                  to={`/event-list/${id}/application`}
                  className="btn btn-primary text-white"
                >
                  Register now
                </Link>
              )}
          </div>
        </div>
        <div
          className="flex-grow-1 text-center pb-4"
          style={{
            minHeight: "250px",
            backgroundImage: `url(${eventData.Picture})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            aspectRatio: "16 / 9",
          }}>
        </div>
      </div>
      <div className="my-4">
        <h2>Venue</h2>
        <a href={eventData.Location}>Location</a>
        <p>{eventData.Venue}</p>
        <h2>Overview</h2>
        <EditorComponent
          initialState={eventData.Overview}
          setEditorDataState={() => { }}
          toolbarHidden={true}
          id="Overview"
          readOnly={true}
          wrapperClassName=""
        />
        <h2>Who Should Attend?</h2>
        <EditorComponent
          initialState={eventData.Audience}
          setEditorDataState={() => { }}
          toolbarHidden={true}
          id="Audience"
          readOnly={true}
          wrapperClassName=""
        />
      </div>
    </div>
  )
}

export default EventDetails
