import * as React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { Article } from '../types/article'
import { Badge, Button } from 'react-bootstrap'
import { Files, Trash, PencilSquare } from 'react-bootstrap-icons'
import { dateFilter, stringFilter } from '../utils/filters'

const STATUS_VISIBLE = {
  'true': 'Visible',
  'false': 'Invisible',
}

type ArticlesTableColumnsProps = {
  editArticle: React.MouseEventHandler<HTMLButtonElement>,
  duplicateArticle: React.MouseEventHandler<HTMLButtonElement>,
  deleteArticle: React.MouseEventHandler<HTMLButtonElement>,
}

const articlesTableColumns = ({ editArticle, duplicateArticle, deleteArticle }: ArticlesTableColumnsProps): ColumnDef<Article>[] => [
  {
    header: () => <span>#</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Id',
    cell: info => info.getValue<string>(),
    filterFn: stringFilter,
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>Title</span>,
    accessorKey: 'Title',
    accessorFn: row => row.Title,
    id: 'Title',
    cell: info => info.getValue(),
    sortingFn: 'text',
  },
  {
    header: () => <span>Visibility</span>,
    accessorKey: 'Visible',
    accessorFn: row => row.Visible,
    id: 'Visible',
    cell: info => {
      const Visible = info.getValue()
      return Visible
        ? <Badge bg="success">Visible</Badge>
        :
        <Badge bg="light" className="text-dark">
          Invisible
        </Badge>
    },
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue<any>(columnId)
      const statusString = STATUS_VISIBLE[value as keyof typeof STATUS_VISIBLE].toLowerCase()
      return Boolean(statusString.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = STATUS_VISIBLE[current.original.Approved as keyof typeof STATUS_VISIBLE].toLowerCase()
      const nextStatus = STATUS_VISIBLE[next.original.Approved as keyof typeof STATUS_VISIBLE].toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    }
  },
  {
    header: () => <span>Created On</span>,
    accessorKey: 'CreatedOn',
    accessorFn: row => row.CreatedOn,
    id: 'CreatedOn',
    cell: info => new Date(info.getValue<string>()).toLocaleDateString('en-GB'),
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>Updated On</span>,
    accessorKey: 'UpdatedOn',
    accessorFn: row => row.UpdatedOn,
    id: 'UpdatedOn',
    cell: info => new Date(info.getValue<string>()).toLocaleDateString('en-GB'),
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>Actions</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Actions',
    cell: info => {
      const Id = info.getValue()
      return (
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <Button
            className="mb-3 me-0 mb-md-0 me-md-3 btn-action border-0 bg-transparent"
            data-id={Id}
            onClick={editArticle}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Edit"
          >
            <PencilSquare size={25} />
          </Button>
          <Button
            className="mb-3 me-0 mb-md-0 me-md-3 btn-action border-0 bg-transparent"
            data-id={Id}
            onClick={duplicateArticle}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Duplicate"
          >
            <Files size={25} />
          </Button>
          <Button
            className="btn-action border-0 bg-transparent"
            data-id={Id}
            onClick={deleteArticle}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
          >
            <Trash size={25} />
          </Button>
        </div>
      )
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  STATUS_VISIBLE,
  articlesTableColumns,
}