import "./Video.css";
import * as React from "react";

const Video = () => {
  return (
    <div className="d-flex align-content-center flex-grow-1 embed-responsive embed-responsive-16by9">
      <iframe
        title="Introduction Video"
        src="https://www.youtube.com/embed/_u14qSEpFBs"
        // width="100%"
        // height="320"
        // style={{ maxWidth: "640px" }}
        className="flex-grow-1 embed-responsive-item video"
      ></iframe>
    </div>
  );
};

export default Video;
