import "./Footer.css"
import * as React from "react";
import { Link } from "react-router-dom";
import Contacts from "../components/Home/Contacts";
import Subscribe from "../components/Subscribe/Subscribe";

const Footer: React.FC = () => {
  return (
    <div className="footer footer-text d-flex flex-column justify-content-center align-items-center mt-5 p-2 border-top">
      <Contacts />
      <Subscribe />
      <p>Copyright © {new Date().getFullYear()} KFAS. All rights reserved.</p>
    </div>
  );
};

export default Footer;
