import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../store'
import * as CounterStore from '../store/Counter'

type CounterProps =
  CounterStore.CounterState
  & typeof CounterStore.actionCreators

const Counter: React.FC<CounterProps> = ({ count, increment, decrement }) => {
  return (
    <React.Fragment>
      <h1 className="display-6 mb-4 text-primary">Counter</h1>
      <p>This is a simple example of a React component.</p>
      <p aria-live="polite">Current count: <strong>{count}</strong></p>
      <button type="button" className="btn btn-primary btn-lg" onClick={increment}>
        Increment
      </button>
    </React.Fragment>
  )
}

export default connect(
  (state: ApplicationState) => state.counter,
  CounterStore.actionCreators
)(Counter)
