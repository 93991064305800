//React
import React from "react";
import { useEffect, useState } from "react";
//React Router
import { useParams, useNavigate } from "react-router";
//Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

type Props = {};
type Option = {
  title: string;
  points: number;
};
type Category = {
  id: number;
  title: string;
  options: Option[];
};
type Criteria = {
  id: number;
  title: string;
  associatedEvents: number[];
  categories: Category[];
};
const initialCriteria: Criteria = {
  id: 0,
  title: "",
  associatedEvents: [12, 1, 3],
  categories: [],
};

const Criteria = (props: Props) => {
  //hooks
  const { id } = useParams();
  const [criteria, setCriteria] = useState<Criteria>(initialCriteria);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    //fetch request based on id
    if (id != "new") {
      setCriteria({
        ...criteria,
        id: Number(id),
        title: `KFAS-criteria-${id}`,
        associatedEvents: [1, 2, 4],
        categories: [
          {
            id: 1,
            title: "Affiliation",
            options: [{ title: "SME", points: 2 }],
          },
        ],
      });
    }
  }, []);
  //handlers
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setToastMessage("Criteria Saved ✅");
    setShowToast(true);
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      navigate(`/admin/event-list`);
    }, 3000);
  };
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Criteria</h1>
      <Form className="mb-3" onSubmit={handleSubmit}>
        {/* ID AND TITLE */}
        <Row>
          <Col xs={2}>
            <Form.Group className="mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <Form.Control
                disabled
                value={id == "new" ? Math.floor(Math.random() * 100) : id}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control defaultValue={criteria.title} />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <p className="fs-5 mb-4">Category Selection</p>
        <Form.Select aria-label="category selection">
          <option value="1">Affiliation</option>
          <option value="2">Position</option>
          <option value="3">Years Company in operation</option>
        </Form.Select>
        <hr />
        <p className="fs-5 mb-4">Final Criteria</p>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default Criteria;
