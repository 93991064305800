import './Observer.css'
import * as React from 'react'

const Observer: React.FC = () => {
  return (
    <form>
      <h1 className="display-6 mb-4 text-primary">Register as Observer</h1>
    </form>
  )
}

export default Observer