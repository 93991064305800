import * as React from 'react'
import Icons from '../../assets/imgs/icons.svg' // Path to your icons.svg
import { Button, Form, Modal, Spinner, Toast, ToastContainer } from 'react-bootstrap'
import { Send } from 'react-bootstrap-icons'
import { submitForm } from '../../utils/forms'

const Contacts: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [showToast, setShowToast] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState('')
  const onSubmitSuggestion = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target as HTMLFormElement)
    try {
      const json = await submitForm(`/api/data/suggestProgram`, formData)
      setToastMessage('Thank you for your suggestion ✅')
    } catch (e) {
      setToastMessage('Something went wrong ❌')
    }
    setShowModal(false)
    setLoading(false)
    setShowToast(true)
  }

  return (
    <>
      <div className="d-flex justify-content-center flex-wrap align-items-stretch gap-3 w-100 p-2">
        <a
          href="https://www.instagram.com/kfasinfo/"
          className="text-decoration-none me-4"
          aria-label="Instagram"
        >
          <span className="d-flex align-items-center">
            <svg className="bi" width="24" height="24">
              <use xlinkHref={`${Icons}#instagram`} />
            </svg>
          </span>
        </a>
        <a
          href="https://twitter.com/@kfasinfo"
          className="text-decoration-none me-4"
          aria-label="Twitter"
        >
          <span className="d-flex align-items-center">
            <svg className="bi" width="24" height="24">
              <use xlinkHref={`${Icons}#twitter`} />
            </svg>
          </span>
        </a>
        <a
          href="https://www.facebook.com/kfasinfo"
          className="text-decoration-none me-4"
          aria-label="Facebook"
        >
          <span className="d-flex align-items-center">
            <svg className="bi" width="24" height="24">
              <use xlinkHref={`${Icons}#facebook`} />
            </svg>
          </span>
        </a>
        <a
          href="https://www.linkedin.com/company/kuwait-foundation-for-the-advancement-of-sciences"
          className="text-decoration-none me-4"
          aria-label="LinkedIn"
        >
          <span className="d-flex align-items-center">
            <svg className="bi" width="24" height="24">
              <use xlinkHref={`${Icons}#linkedin`} />
            </svg>
          </span>
        </a>
        <a
          href="https://www.youtube.com/kfasinfo"
          className="text-decoration-none me-4"
          aria-label="YouTube"
        >
          <span className="d-flex align-items-center">
            <svg className="bi" width="24" height="24">
              <use xlinkHref={`${Icons}#youtube`} />
            </svg>
          </span>
        </a>
        {/* <a href="tel:+965-22278100" className="text-decoration-none me-4 aria-label="Telephone"">
        <span className="d-flex align-items-center">
          <svg className="bi me-1" width="24" height="24">
            <use xlinkHref={`${Icons}#telephone`} />
          </svg>
          +965-22278100 Ext:1883/1885
        </span>
      </a> */}
        <a
          href="mailto:training@kfas.org.kw"
          className="text-decoration-none me-4"
          aria-label="Email"
        >
          <span className="d-flex align-items-center ">
            <svg className="bi me-1" width="24" height="24">
              <use xlinkHref={`${Icons}#email`} />
            </svg>
            training@kfas.org.kw
          </span>
        </a>
        <Button
          // as="a"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Filter"
          // variant="link"
          onClick={() => { setShowModal(true) }}>
          <Send />
          <small className="ps-1">Contact us</small>
        </Button>
      </div>
      <Modal show={showModal} onHide={() => { setShowModal(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Contact us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            In KFAS, we love to hear from you. Please send us your suggestions by filling out the form below:
          </p>
          <Form method="POST" onSubmit={onSubmitSuggestion}>
            <Form.Group className="mb-3">
              <Form.Label>Title:</Form.Label>
              <Form.Control type="text" id="title" name="title" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                required
                name="description"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Your email:</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@kfas.org.kw"
                id="email"
                name="email"
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="secondary" onClick={() => { setShowModal(false) }}>
                Close
              </Button>
              {loading
                ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : <Button variant="primary" type="submit">
                  Suggest
                </Button>
              }

            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

export default Contacts
