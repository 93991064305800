//React
import * as React from 'react'
//Bootstrap
import BootstrapTable from 'react-bootstrap/Table'
import ColumnFilter from '../../components/ColumnFilter/ColumnFilter'
//Types
import { flexRender } from '@tanstack/react-table'
import { Table as ReactTable } from '@tanstack/react-table'
import PaginationBar from '../PaginationBar/PaginationBar'

type TableProps = {
  table: ReactTable<any>
}

const Table = ({ table }: TableProps) => {
  return (
    <>
      <PaginationBar table={table} />
      <BootstrapTable striped bordered hover responsive>
        <thead>
          {table.getHeaderGroups().map(headerGroup =>
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header =>
                <th key={header.id} colSpan={header.colSpan} className="position-relative">
                  {header.isPlaceholder
                    ? null
                    : <>
                      <div
                        className={`user-select-none${header.column.getCanSort() ? ' cursor-pointer' : ''}`}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                      <div
                        className="position-absolute" style={{
                          top: '5px',
                          right: '5px',
                        }}
                      >
                        {{ asc: ' ▲', desc: ' ▼', }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <div>
                          <ColumnFilter column={header.column} table={table} />
                        </div>
                      ) : null}
                    </>}
                </th>
              )}
            </tr>
          )}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BootstrapTable>
    </>
  )
}

export default Table