//Redux
import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'

export interface ProgressBarState {
  visible: boolean,
  percentage: number
}

const initialState: ProgressBarState = {
  visible: false,
  percentage: 0
}

export const ProgressBarSlice = createSlice({
  name: 'progressBar',
  initialState,
  reducers: {
    setProgressBarVisibility(state, action: PayloadAction<boolean>) {
      state.visible = action.payload
    },
    setProgressBarPercentage(state, action: PayloadAction<number>) {
      state.percentage = action.payload
    },
    setProgressBar(state, action: PayloadAction<ProgressBarState>) {
      Object.assign(state, action.payload) // Copy payload properties to state properties
    }
  }
})

export const { setProgressBarVisibility, setProgressBarPercentage, setProgressBar } = ProgressBarSlice.actions
export default ProgressBarSlice.reducer