//Props
import { FeedbackQuestion, Scale, ScalePoint } from "../Props";

const defaultTenScale: Scale = {
  minLabel: "Low Understanding",
  maxLabel: "High Understanding",
  min: 1,
  max: 10,
  points: [
    { text: "1", value: "1" },
    { text: "2", value: "2" },
    { text: "3", value: "3" },
    { text: "4", value: "4" },
    { text: "5", value: "5" },
    { text: "6", value: "6" },
    { text: "7", value: "7" },
    { text: "8", value: "8" },
    { text: "9", value: "9" },
    { text: "10", value: "10" },
  ],
};
const defaultFiveScale: Scale = {
  min: 1,
  max: 5,
  points: [
    { text: "Strongly Disagree", value: "1" },
    { text: "Disagree", value: "2" },
    { text: "Neither Agree nor Disagree", value: "3" },
    { text: "Agree", value: "4" },
    { text: "Strongly Agree", value: "5" },
  ],
};
const defaultTrueFalse: Scale = {
  points: [
    { text: "True", value: "1" },
    { text: "False", value: "0" },
  ],
};
export const questions: FeedbackQuestion[] = [
  {
    question:
      "Prior to attending the program, rate your level of understanding and knowledge of the content addressed in the course:",
    id: 1,
    questionType: "scale",
    scale: defaultTenScale,
    answer: "",
  },
  {
    question:
      "After attending the program, rate your level of understanding and knowledge of the content addressed in the course:",
    id: 2,
    questionType: "scale",
    scale: defaultTenScale,
    answer: "",
  },
  {
    question: "The training objectives were identified, followed and applied.",
    id: 3,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question: "The program was interesting and informative.",
    id: 4,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "The information presented was comprehensible and concepts, if any, were presented with examples.",
    id: 5,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question: "The instructor delivered a clear, engaging and logical session.",
    id: 6,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question: "The instructor was well organized and prepared.",
    id: 7,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "The instructor's explanation of concepts and instructions were at an understandable level.",
    id: 8,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "The instructor encouraged participation and responded to participants' questions reasonably.",
    id: 9,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "I am willing to listen appreciatively and effectively to others.",
    id: 10,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question: "I am willing to try new influence styles to facilitate change.",
    id: 11,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question: "I know how to motivate people in my organization around change.",
    id: 12,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "I am more confident on how to effectively lead change across my organization.",
    id: 13,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "The playful approach to this training helped me stay engaged the whole time.",
    id: 14,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question:
      "The playful approach to this training accelerated my openness to learn something new.",
    id: 15,
    questionType: "scale",
    scale: defaultFiveScale,
    answer: "",
  },
  {
    question: "I found balance between the lecture and the group activities.",
    id: 16,
    questionType: "scale",
    scale: defaultTrueFalse,
    answer: "",
  },
  {
    question: "This course was relevant to my growth and development.",
    id: 17,
    questionType: "scale",
    scale: defaultTrueFalse,
    answer: "",
  },
  {
    question:
      "This course was helpful and will have an impact on my work environment.",
    id: 18,
    questionType: "scale",
    scale: defaultTrueFalse,
    answer: "",
  },
  {
    question:
      "The KFAS representative was helpful and made sure the environment was positive.",
    id: 19,
    questionType: "scale",
    scale: defaultTrueFalse,
    answer: "",
  },
  {
    question:
      "On a scale from 1-10, how likely are you to recommend this workshop to a friend?",
    id: 20,
    questionType: "scale",
    scale: { ...defaultTenScale, minLabel: "Unlikely", maxLabel: "Likely" },
    answer: "",
  },
  {
    question: "How are you most likely to use what you have learned?",
    id: 21,
    questionType: "shortAnswer",
    answer: "",
  },
  {
    question:
      "Having completed this program, what would you most like to learn next?",
    id: 22,
    questionType: "shortAnswer",
    answer: "",
  },
  {
    question:
      "Please share any comments you have to improve this training/course:",
    id: 23,
    questionType: "shortAnswer",
    answer: "",
  },
];
