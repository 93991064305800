class Particle {
  x: number
  y: number
  size: number
  speedX: number
  speedY: number
  color: string
  constructor(mouse: { x: number; y: number }, hslColor: { hue: number, saturation: number, lightness: number }) {
    const { hue, saturation, lightness } = hslColor
    this.x = mouse.x
    this.y = mouse.y
    this.size = (Math.random() * 4) + 0
    this.speedX = (Math.random() * 2) - 1.5
    this.speedY = (Math.random() * 2) - 1.5
    this.color = `hsl(${hue},${saturation}%,${lightness}%)`
  }
  update() {
    this.x += this.speedX
    this.y += this.speedY
    // if (this.size > 0.2) this.size -= 0.1
  }
  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath()
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2)
    ctx.fillStyle = this.color
    ctx.fill()
  }
}

const animate = (ctx: CanvasRenderingContext2D, particlesArray: Particle[], lineDrawCutOffDistance: number, maxParticles: number) => {
  ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)
  for (let i = 0; i < particlesArray.length; i++) {
    particlesArray[i].update()
    particlesArray[i].draw(ctx)
    for (let j = i; j < particlesArray.length; j++) {
      const dx = particlesArray[i].x - particlesArray[j].x
      const dy = particlesArray[i].y - particlesArray[j].y
      const distance = Math.sqrt(dx * dx + dy * dy)
      if (distance < lineDrawCutOffDistance) {
        ctx.beginPath()
        ctx.strokeStyle = particlesArray[i].color
        ctx.lineWidth = 0.2
        ctx.moveTo(particlesArray[i].x, particlesArray[i].y)
        ctx.lineTo(particlesArray[j].x, particlesArray[j].y)
        ctx.stroke()
      }
    }
    if (
      particlesArray[i].size <= 0.3 ||
      particlesArray[i].x < 0 ||
      particlesArray[i].x > window.innerWidth ||
      particlesArray[i].y < 0 ||
      particlesArray[i].y > window.innerHeight
    ) {
      particlesArray.splice(i, 1)
      i--
    }
    if (particlesArray.length >= maxParticles) {
      particlesArray.splice(0, 1)
    }
  }
  requestAnimationFrame(() => { animate(ctx, particlesArray, lineDrawCutOffDistance, maxParticles) })
}

export {
  Particle,
  animate
}