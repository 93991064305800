import './ReportCard.css'
import * as React from 'react'
import Card from 'react-bootstrap/Card'

type ReportCardProps = {
  title: string
  children: React.ReactNode
}

const ReportCard = ({ title, children }: ReportCardProps) => {
  return (
    <Card className="report-card shadow my-3">
      <Card.Header className="bg-transparent text-primary">
        <h1>{children}</h1>
      </Card.Header>
      <Card.Body>
        <Card.Title className="d-flex justify-content-center align-items-center">
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  )
}

export default ReportCard