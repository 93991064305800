//React
import React from "react";
import { useEffect, useState } from "react";
//React Router
import { useParams, useNavigate } from "react-router";
//Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Spinner from "../../components/Spinner/Spinner";

//Text Editor
import EditorComponent from "../../components/TextEditor/EditorComponent";
type Props = {};

type Article = {
  Id: number;
  Title: string;
  Content: string;
  CreatedOn: string;
  CreatedBy: string;
  UpdatedOn: string;
  UpdatedBy: string;
  Visible: boolean;
  IsDeleted: boolean;
};
type response = {
  type: string;
  message: string;
  id: number;
};

const initialArticle: Article = {
  Id: -1,
  Title: "",
  Content:
    '{"blocks":[{"key":"p4k4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  CreatedBy: "fd8fb8cb-4365-4cf4-bcda-dcbb0e0d77b8",
  CreatedOn: new Date().toISOString(),
  UpdatedBy: "fd8fb8cb-4365-4cf4-bcda-dcbb0e0d77b8",
  UpdatedOn: new Date().toISOString(),
  Visible: false,
  IsDeleted: false,
};

const ArticleInfo = (props: Props) => {
  //hooks
  const { id } = useParams();
  const [article, setArticle] = useState<Article>(initialArticle);
  const [showToast, setShowToast] = useState(false);
  const [loading, setloading] = useState(true);

  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    //fetch request based on id
    const getArticle = async () => {
      if (id != "new") {
        const rawResponse = await fetch(`/api/article/article?id=${id}`, {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
        });
        const content = (await rawResponse.json()) as Article;
        setArticle({ ...content });
      }
      setloading(false);
    };
    getArticle();
  }, []);
  //handlers
  //Create Article
  const createArticle = async () => {
    const rawResponse = await fetch("/api/article/article", {
      method: "POST",
      body: JSON.stringify(article),
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    const content = await rawResponse.json();
    return content;
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //create article
    const result: response = await createArticle();
    if (result.type == "SUCCESS") {
      setArticle({ ...article, Id: result.id });
      setToastMessage("Article Saved ✅");
      setShowToast(true);
    } else {
      setToastMessage("Something went wrong ❌");
      setShowToast(true);
    }
    // const timeout = setTimeout(() => {
    //   clearTimeout(timeout);
    //   navigate(`/admin/newsfeed-list`);
    // }, 3000);
  };

  const setEditorArticleState = (id: string, value: string) => {
    setArticle({ ...article, [id]: value });
  };
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setArticle({ ...article, [e.target.id]: e.target.value });
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArticle({ ...article, [e.target.id]: e.target.checked });
  };
  if (loading) return (<Spinner />);
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Article Info</h1>
      <Form className="mb-3" onSubmit={handleSubmit}>
        {/* ID AND TITLE */}
        <Row>
          <Col xs={2}>
            <Form.Group className="mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <Form.Control disabled value={id == "new" ? "" : article.Id} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              controlId="Title"
              onChange={handleChange}
            >
              <Form.Label>Title</Form.Label>
              <Form.Control defaultValue={article.Title} />
            </Form.Group>
          </Col>
        </Row>
        {/* DESCRIPTION */}
        <Row>
          <Form.Group className="mb-3" controlId="Content">
            <Form.Label>Description</Form.Label>
            {/* <Form.Control
              as="textarea"
              rows={20}
              defaultValue={article.description}
            /> */}
            <EditorComponent
              initialState={article.Content}
              id="Content"
              setEditorDataState={setEditorArticleState}
            />
          </Form.Group>
        </Row>
        {/* IS VISIBLE */}
        <Row>
          <Form.Group className="mb-3" controlId="Visible">
            <Form.Check
              type="checkbox"
              label="Visible"
              onChange={handleCheckboxChange}
              defaultChecked={article.Visible}
            />
          </Form.Group>
        </Row>
        <Row>
          <Alert variant="info">
            <p>
              Once an article is visible, it will be shown publically on the
              newsfeed page.
            </p>
          </Alert>
        </Row>
        <Button variant="primary" type="submit">
          Save
        </Button>
        <Button variant="outline-primary" className="ms-3">
          Send Email
        </Button>
      </Form>
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ArticleInfo;
