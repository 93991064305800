import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Funnel } from 'react-bootstrap-icons'
import { filters } from '../../types/filter'

type Props = {
  filters: filters[]
  setFilters: (filters: filters[]) => void
}

const Filter = ({ filters, setFilters }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [currentFilters, setCurrentFilters] = useState<filters[]>(filters)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFilters(filters => filters.map(filter => {
      const [filterType, filtersItemName] = event.target.id.split('-')
      if (filter.Name !== filterType) return filter
      filter.Filters = filter.Filters.map(item => {
        if (item.Name !== filtersItemName) return item
        item.Status = event.target.checked
        return item
      })
      return filter
    }))
  }
  const onFilter = () => {
    setShowModal(false)
    setFilters(currentFilters)
  }
  
  return (
    <>
      <Button
        data-toggle="tooltip"
        data-placement="bottom"
        title="Filter"
        variant="primary"
        onClick={() => setShowModal(true)}
      >
        <Funnel />
        <div><small>Filter</small></div>
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter By:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filters.map(filter => (
            <div key={filter.Name}>
              <p className="fw-bold">{filter.Name}</p>
              <div key={filter.Name} className="mb-3">
                {filter.Filters.map(filterItem => (
                  <Form.Check
                    inline
                    label={`${filterItem.Name}`}
                    name={`${filter.Name}`}
                    type="checkbox"
                    id={`${filter.Name}-${filterItem.Name}`}
                    key={`${filter.Name}-${filterItem.Name}`}
                    onChange={handleChange}
                    defaultChecked={filterItem.Status}
                  />
                ))}
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onFilter}>
            Apply filters
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Filter
