//React
import * as React from "react";
//React Router
import { Link } from "react-router-dom";
//Components
import NewsfeedComponent from "../Newsfeed/Newsfeed";
//Types
import { Article } from "../../types/article";

//props
type NewsfeedProps = {
  articles: Article[];
};
const NewsFeed = ({ articles }: NewsfeedProps) => {
  return (
    <div className="d-flex flex-column p-2 mt-5">
      <h1 className="text-center display-4">Newsfeed</h1>
      <div className="d-flex justify-content-end align-items-center mb-2 position-relative">
        <Link
          className="ms-auto btn btn-outline-primary btn-sm position-absolute"
          to="/newsfeed"
          style={{ bottom: "15px" }}
        >
          See All
        </Link>
      </div>
      {articles.length == 0 && (
        <p className="text-center">No articles are available</p>
      )}
      {articles.map((article, index) => (
        <NewsfeedComponent
          key={`article-${article.Id ?? index}`}
          title={article.Title}
          date={new Date(article.UpdatedOn)}
          body={article.Content}
          classes="mb-5"
          id={article.Id}
        />
      ))}
    </div>
  );
};

export default NewsFeed;
