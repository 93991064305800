import './NavMenu.css'
import * as React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { logout, getAuth } from '../store/AuthSlice'
import RequireLogin from '../guards/RequireLogin'
import RequireLogout from '../guards/RequireLogout'

const NavMenu: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // const state = useAppSelector(state => state.auth.state)
  const [activeKey, setActiveKey] = React.useState<string>('')
  const [expanded, setExpanded] = React.useState<boolean>(false)
  // const navBar = React.useRef()
  const logoutHandler = async () => {
    dispatch(logout())
    navigate(`/`)
    window.location.reload()
  }
  // React.useEffect(() => { dispatch(getAuth()) }, [state])
  React.useEffect(() => {
    const pathName = window.location.pathname
    const pathEventKey: { [key: string]: string } = {
      '/': 'home',
      '/event-list': 'events',
      '/newsfeed': 'newsfeed',
      '/faq': 'faq',
      '/register': 'register',
      '/login': 'login',
      '/dashboard': 'dashboard'
    }
    setActiveKey(pathEventKey[pathName])
  }, [])
  const onSelectHandler = (eventKey: string | null) => {
    setActiveKey(eventKey ?? '')
    setExpanded(false)
  }
  return (
    <header className="border-bottom fixed-top shadow-sm bg-white">
      <Navbar
        expand="lg"
        className="p-0 m-0"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
      >
        <Navbar.Brand
          as={Link}
          to="/"
          className="d-flex align-items-center d-flex justify-content-center ms-3"
        >
          <img
            src="/logo.png"
            alt="KFAS Logo"
            width={120}
            height={56}
            style={{
              width: "120px",
              height: "auto"
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle id="toggleButton" aria-controls="navigation" className="border-0 p-3 btn btn-primary" />
        <Navbar.Collapse id="navigation" className="text-center">
          <Nav className="ms-auto" activeKey={activeKey} onSelect={onSelectHandler}>
            <Nav.Link as={Link} to="/" eventKey={'home'} className="fs-5 px-2 py-3">
              Home
              <div className="line"></div>
            </Nav.Link>
            <Nav.Link as={Link} to="/event-list" eventKey={'events'} className="fs-5 px-2 py-3">
              Offerings
              <div className="line"></div>
            </Nav.Link>
            <Nav.Link as={Link} to="/newsfeed" eventKey={'newsfeed'} className="fs-5 px-2 py-3">
              Newsfeed
              <div className="line"></div>
            </Nav.Link>
            <Nav.Link as={Link} to="/faq" eventKey={'faq'} className="fs-5 px-2 py-3">
              FAQ
              <div className="line"></div>
            </Nav.Link>
            <RequireLogin>
              <Nav.Link as={Link} to="/dashboard" eventKey={'dashboard'} className="fs-5 px-2 py-3">
                Dashboard
                <div className="line"></div>
              </Nav.Link>
              <Button variant="primary" className="text-light fs-5 py-3" onClick={logoutHandler}>
                Logout
              </Button>
            </RequireLogin>
            <RequireLogout>
              <Nav.Link as={Link} to="/register" eventKey={'register'} className="fs-5 px-2 py-3">
                Register
                <div className="line"></div>
              </Nav.Link>
              <Nav.Link as={Link} to="/login" eventKey={'login'} className="fs-5 px-2 py-3">
                Login
                <div className="line"></div>
              </Nav.Link>
            </RequireLogout>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default NavMenu
