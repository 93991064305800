import './Generic.css'
import React from 'react'

type GenericProps = {
  title: string
  children: React.ReactNode
}

const Generic = ({ title, children }: GenericProps) => (
  <>
    <h1>{title}</h1>
    <div>{children}</div>
  </>
)

export default Generic
