import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Table from "react-bootstrap/Table"

const FAQ = () => {
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Frequently Asked Questions</h1>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What type of offerings are available at KFAS Learn?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              There are four types of offerings:
            </p>
            <ul>
              <li>OE (Open Enrollment).</li>
              <li>LEE (Local Executive Education). </li>
              <li>AEE (Abroad Executive Education).</li>
              <li>CP (Custom Programs).</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            How do I know which offering I am eligible to apply for?
          </Accordion.Header>
          <Accordion.Body>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Open Enrollment (OE)</th>
                  <th>Local Executive Education (LEE)</th>
                  <th>Abroad Executive Education (AEE)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Years of full-time professional experience (minimum)</td>
                  <td>1+</td>
                  <td>5+</td>
                  <td>7+</td>
                </tr>
                <tr>
                  <td>Years of management experience (minimum)</td>
                  <td>1+</td>
                  <td>5+</td>
                  <td>7+</td>
                </tr>
                <tr>
                  <td>Format</td>
                  <td>In-person</td>
                  <td>In-person</td>
                  <td>In-person</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>Locally held in Kuwait</td>
                  <td>Locally held in Kuwait</td>
                  <td>Abroad</td>
                </tr>
                <tr>
                  <td>Times I can attend per year</td>
                  <td>1 program</td>
                  <td>1 program</td>
                  <td>1 program</td>
                </tr>
              </tbody>
            </Table>
            Each program under the offerings might have specific requirements based on the program criteria.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            How many programs can I attend a year?
          </Accordion.Header>
          <Accordion.Body>
            You can attend one from each of the four types of offerings per year, subject to eligibility and acceptance.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            What is KFAS Learn?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                KFAS Learn is an online portal that showcases available offerings and streamlines the application process, for learning and development.
              </li>
              <li>Only companies registered in Kuwait are eligible to use KFAS Learn.</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Is using KFAS Learn a requirement to apply?
          </Accordion.Header>
          <Accordion.Body>
            Each prospective applicant must submit their application and required documents via the KFAS Learn system for their application to be considered complete.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            How do I register to KFAS Learn as a company?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Companies must register and create a company account on KFAS Learn. Once a company account has been created and verified, you will be able to monitor and verify all employees’ registration and applications.
              </li>
              <li>
                Each company requires to specify HR representatives under their company to manage the company account.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            How do I register to KFAS Learn as Human Resources representative?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Once the company has been verified and registered the Human Resources representative can register their individual profiles under their company.
              </li>
              <li>
                You will need to associate your individual profile with the company profile by selecting your company in the drop-down menu.
              </li>
              <li>
                Once the company profile and Human Resources individual profile has been associated with each other the employees of the company can now create their individual profiles under the company.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            How do I register to KFAS Learn as an individual?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                First your company profile must be created and verified in KFAS Learn. If your company profile has not been created on KFAS Learn please contact your HR focal point.
              </li>
              <li>
                You must be employed in Kuwait.
              </li>
              <li>
                Individuals must register and create an individual account on KFAS Learn. The HR focal point will verify the individual account creation under the company.
              </li>
              <li>
                Once the account is verified you can apply for offerings.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            How do I apply to a program under offerings?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                After creating and verifying your individual account on KFAS Learn, each program has a its own criteria. This criterion is always listed in the program overview.
              </li>
              <li>
                Each program outlines a specific registration deadline. Please make sure to submit your application before the registration deadline.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            Which programs require a nomination letter from the company?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                LEE, AEE, and CP offerings require a nomination letter from the company, attesting to the applicants’ years of managerial experience and they are sponsoring them to attend the program.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey="9">
          <Accordion.Header>
            How can I maximize my chances of getting in?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Make sure to read the program description and eligibility requirements and submit all required documents before the registration deadline.
              </li>
              <li>
                It is very important to read the eligibility criteria to see if you meet minimum requirements as your application will be verified against them.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item> */}
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            I made a mistake while applying/registering! What can I do?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Email <a href="mailto:training@kfas.org.kw">training@kfas.org.kw</a> with any problems with the application.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
          <Accordion.Header>
            What is the cancellation policy?
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Individuals are able to withdraw their application on KFAS Learn any time before the registration deadline window.
              </li>
              <li>
                Once accepted into the program the individual is able to withdraw their acceptance within the first week of receiving it. Afterward the system is locked for withdrawals and you will need to contact your HR focal point.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

export default FAQ
