import * as React from "react";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
// Redux
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { login, getAuth } from "../store/AuthSlice";
import { convertFormToJson } from "../utils/forms";
import { Modal, Toast, ToastContainer } from "react-bootstrap";
import { submitForm } from "../utils/forms";

const LogIn: React.FC = ({ }) => {
  const [loading, setloading] = useState(true);
  const [alert, setAlert] = useState<string>('');
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const state = useAppSelector(selector => selector.auth.state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    setloading(true);
    event.preventDefault();
    const formData = convertFormToJson(event.target as HTMLFormElement);
    const loginAttempt = await dispatch(login(formData)) as any
    if (loginAttempt?.payload?.state !== 'LOGGED_IN')
      setAlert(`Something is not right, try again.`)
    else window.location.reload()
    setloading(false);
  };
  useEffect(() => {
    dispatch(getAuth());
    if (state === "LOGGED_IN") navigate("/dashboard");
    setloading(false);
  }, [state]);
  const handleClose = () => { setShow(false); };
  const handleShow = () => { setShow(true) };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setloading(true);
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    try {
      const xsrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN='))
        ?.split('=')[1] ?? ''
      const headers = {
        'X-XSRF-TOKEN': xsrfToken,
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
      }
      const body = JSON.stringify(Object.fromEntries(formData))
      const response = await fetch(`/api/account/forgotpassword`, { method: 'POST', body, headers, })
      const json = await response.json()
      setToastMessage("Your request has been received, if you have an account with us you will receive an email.");
    } catch (e) {
      setToastMessage("Something went wrong, try again ❌");
    }
    setShow(false);
    setloading(false);
    setShowToast(true);
  };
  if (loading) return (<Spinner />);
  return (
    <>
      <div className="p-2 row">
        <h1 className="display-6 mb-4 text-primary">Login</h1>
        {alert.length > 0 && (
          <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: alert }}></div>
        )}
        <Form className="col-12 col-md-6" onSubmit={loginHandler} action="POST">
          <FormGroup>
            <Form.Label htmlFor="username">Civil Id</Form.Label>
            <FormControl
              aria-label="Username"
              id="username"
              name="username"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Form.Label htmlFor="password">Password</Form.Label>
            <FormControl
              aria-label="Password"
              id="password"
              name="password"
              type="password"
            />
          </FormGroup>
          <Link to="#" onClick={handleShow} className="d-block mt-4 mb-4">
            Forgot Password?
          </Link>
          <div className="d-flex justify-content-between align-items-center">
            <Button type="submit" color="primary">
              Login
            </Button>
            <p>
              Dont have an account? <Link to="/register">Register</Link>
            </p>
          </div>
        </Form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgotten Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            method="POST"
            onSubmit={handleSubmit}
            className="was-validated"
          >
            <Form.Group className="mb-3">
              <Form.Label>Please enter your Civil Id, if you have an account we will send you a link to your email to reset the password.</Form.Label>
              <Form.Control
                type="text"
                id="CivilId"
                name="CivilId"
                placeholder="Civil Id Number"
                className="is-invalid"
                pattern="\d{12}"
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default LogIn;
