import * as React from 'react'
import { Link } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import { Applicant } from '../types/applicant'
import { ColumnDef } from '@tanstack/react-table'
import { dateFilter } from '../utils/filters'

const STATUS = {
  'null': 'Pending',
  'true': 'Accepted',
  'false': 'Rejected',
}

const STATUS_COLOR = {
  'Pending': 'secondary',
  'Accepted': 'success',
  'Rejected': 'danger',
  'Withdrawn': 'warning',
}

type ApprovalsTableColumnsProps = {
  onApprovalChange: React.ChangeEventHandler<HTMLSelectElement>,
}

const approvalsTableColumns = ({ onApprovalChange }: ApprovalsTableColumnsProps): ColumnDef<Applicant>[] => [
  {
    header: 'Employee',
    columns: [
      {
        header: () => <span>First Name</span>,
        accessorKey: 'FirstNameEn',
        accessorFn: row => ({ User: row.User, FirstName: row.FirstNameEn }),
        id: 'FirstNameEn',
        cell: info => {
          const { User, FirstName } = info.getValue<{ User: string, FirstName: string }>()
          return <Link to={`/profile/${User}`}>{FirstName}</Link>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const { FirstName } = row.getValue<{ User: string, FirstName: string }>(columnId)
          return Boolean(FirstName.toLowerCase().includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentFirstName = current.original.FirstNameEn.toLowerCase()
          const nextFirstName = next.original.FirstNameEn.toLowerCase()
          return currentFirstName === nextFirstName
            ? 0
            : currentFirstName > nextFirstName
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Middle Name</span>,
        accessorKey: 'MiddleNameEn',
        accessorFn: row => ({ User: row.User, MiddleName: row.MiddleNameEn }),
        id: 'MiddleNameEn',
        cell: info => {
          const { User, MiddleName } = info.getValue<{ User: string, MiddleName: string }>()
          return <Link to={`/profile/${User}`}>{MiddleName}</Link>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const { MiddleName } = row.getValue<{ User: string, MiddleName: string }>(columnId)
          return Boolean(MiddleName.toLowerCase().includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentMiddleName = current.original.MiddleNameEn.toLowerCase()
          const nextMiddleName = next.original.MiddleNameEn.toLowerCase()
          return currentMiddleName === nextMiddleName
            ? 0
            : currentMiddleName > nextMiddleName
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Last Name</span>,
        accessorKey: 'LastNameEn',
        accessorFn: row => ({ User: row.User, LastName: row.LastNameEn }),
        id: 'LastNameEn',
        cell: info => {
          const { User, LastName } = info.getValue<{ User: string, LastName: string }>()
          return <Link to={`/profile/${User}`}>{LastName}</Link>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const { LastName } = row.getValue<{ User: string, LastName: string }>(columnId)
          return Boolean(LastName.toLowerCase().includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentLastName = current.original.LastNameEn.toLowerCase()
          const nextLastName = next.original.LastNameEn.toLowerCase()
          return currentLastName === nextLastName
            ? 0
            : currentLastName > nextLastName
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Organization (at "Applied On")</span>,
        accessorKey: 'Data.Profile.OrganizationNameEn',
        accessorFn: row => JSON.parse(`${row.Data}` ?? '{}').Profile?.OrganizationNameEn,
        id: 'OrganizationNameEn',
        cell: info => {
          return info.getValue() ?? 'N/A'
        },
        sortingFn: 'alphanumeric'
      },
    ],
  },
  {
    header: 'Event Details',
    columns: [
      {
        header: () => <span>Event Name</span>,
        accessorKey: 'Event',
        accessorFn: row => ({ EventId: row.EventId, EventName: row.Event }),
        id: 'Event',
        cell: info => {
          const { EventId, EventName } = info.getValue<{ EventId: number, EventName: string }>()
          return <Link to={`/event-list/${EventId}`}>{EventName}</Link>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const { EventName } = row.getValue<{ EventId: number, EventName: string }>(columnId)
          return Boolean(EventName.toLowerCase().includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentEvent = current.original.Event.toLowerCase()
          const nextEvent = next.original.Event.toLowerCase()
          return currentEvent === nextEvent
            ? 0
            : currentEvent > nextEvent
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Event Type</span>,
        accessorKey: 'EventType',
        accessorFn: row => row.EventType,
        id: 'EventType',
        cell: info => info.getValue(),
        sortingFn: 'alphanumeric',
      },
      {
        header: () => <span>From Date</span>,
        accessorKey: 'FromDate',
        accessorFn: row => row.FromDate,
        id: 'FromDate',
        cell: info => new Date(info.getValue()).toLocaleDateString('en-GB'),
        filterFn: dateFilter,
        sortingFn: 'datetime',
      },
      {
        header: () => <span>To Date</span>,
        accessorKey: 'ToDate',
        accessorFn: row => row.ToDate,
        id: 'ToDate',
        cell: info => new Date(info.getValue()).toLocaleDateString('en-GB'),
        filterFn: dateFilter,
        sortingFn: 'datetime',
      },
      {
        header: () => <span>Registration Deadline</span>,
        accessorKey: 'Deadline',
        accessorFn: row => row.Deadline,
        id: 'Deadline',
        cell: info => {
          const date = new Date(info.getValue())
          const dateString = date.toLocaleDateString('en-GB')
          return (
            <div
              {...{
                className: date < new Date()
                  ? 'text-bg-danger px-1'
                  : undefined
              }}
            >
              {dateString}
            </div>)
        },
        filterFn: dateFilter,
        sortingFn: 'datetime',
      },
    ],
  },
  {
    header: 'Application',
    columns: [
      {
        header: () => <span>Applied On</span>,
        accessorKey: 'CreatedOn',
        accessorFn: row => row.CreatedOn,
        id: 'CreatedOn',
        cell: info => new Date(info.getValue()).toLocaleDateString('en-GB'),
        filterFn: dateFilter,
        sortingFn: 'datetime',
      },
      {
        header: () => <span>Application Form</span>,
        accessorKey: 'Form',
        accessorFn: row => row.Id,
        id: 'Form',
        cell: info => <Link to={`/hr/application/${info.getValue()}`}>Application</Link>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      // {
      //   header: () => <span>Profile</span>,
      //   accessorKey: 'User',
      //   accessorFn: row => row.User,
      //   id: 'User',
      //   cell: info => <Link to={`/profile/${info.getValue()}`}>Profile</Link>,
      //   enableSorting: false,
      //   enableColumnFilter: false,
      // },
      {
        header: () => <span>HR Approval</span>,
        accessorKey: 'Approved',
        accessorFn: row => ({ Id: row.Id, Approved: row.Approved, Deadline: row.Deadline }),
        id: 'Approved',
        cell: info => {
          const { Id, Approved, Deadline } = info.getValue()
          return <Form.Select
            id={`${Id}`}
            aria-label="approval"
            defaultValue={`${Approved}`}
            className={`${(Approved !== null || (new Date(Deadline)) < new Date()) && "disabled"}`}
            onChange={onApprovalChange}
            style={{ minWidth: '128px' }}
          >
            <option value="null">Pending</option>
            <option value="true">Accepted</option>
            <option value="false">Rejected</option>
          </Form.Select>
        },
        filterFn: (row, columnId, filterValue) => {
          const search = filterValue.toLowerCase()
          const value = row.getValue<any>(columnId).Approved
          const statusString = STATUS[value as keyof typeof STATUS].toLowerCase()
          return Boolean(statusString.includes(search))
        },
        sortingFn: (current: any, next: any) => {
          const currentStatus = STATUS[current.original.Approved as keyof typeof STATUS].toLowerCase()
          const nextStatus = STATUS[next.original.Approved as keyof typeof STATUS].toLowerCase()
          return currentStatus === nextStatus
            ? 0
            : currentStatus > nextStatus
              ? 1
              : -1
        },
      },
      {
        header: () => <span>Status</span>,
        accessorKey: 'Status',
        accessorFn: row => row.Status,
        id: 'Status',
        cell: info =>
          <Badge bg={`${STATUS_COLOR[info.getValue() as keyof typeof STATUS_COLOR]}`}>
            {info.getValue()}
          </Badge>,
        sortingFn: 'text',
      },
    ],
  },
]

export {
  STATUS,
  STATUS_COLOR,
  approvalsTableColumns,
}