import * as React from 'react'
import Container from 'react-bootstrap/Container'
import NavMenu from './NavMenu'
import Footer from './Footer'

export default (props: { children?: React.ReactNode }) => {
  return (
    <React.Fragment>
      <NavMenu />
      <Container>
        {props.children}
      </Container>
      <Footer />
    </React.Fragment>
  )
}
