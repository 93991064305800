import * as React from 'react'
import { Link } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import { Subscriber } from '../types/subscriber'
import { dateFilter, stringFilter } from '../utils/filters'
import { Button } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'

const STATUS_ACTIVE = {
  'true': 'Active',
  'false': 'Inactive',
}

type SubscribersTableColumnsProps = {
  deleteSubscriber: React.MouseEventHandler<HTMLButtonElement>
}

const subscribersTableColumns = ({ deleteSubscriber }: SubscribersTableColumnsProps): ColumnDef<Subscriber>[] => [
  {
    header: () => <span>Id</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Id',
    cell: info => info.getValue(),
    filterFn: stringFilter,
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>Email</span>,
    accessorKey: 'Email',
    accessorFn: row => row.Email,
    id: 'Email',
    cell: info => info.getValue(),
    sortingFn: 'alphanumeric',
  },
  {
    header: () => <span>Active</span>,
    accessorKey: 'Active',
    accessorFn: row => row.Active,
    id: 'Active',
    cell: info => info.getValue()
      ? 'Active'
      : 'Inactive',
    filterFn: (row, columnId, filterValue) => {
      const search = filterValue.toLowerCase()
      const value = row.getValue<any>(columnId)
      const statusString = STATUS_ACTIVE[value as keyof typeof STATUS_ACTIVE].toLowerCase()
      return Boolean(statusString.includes(search))
    },
    sortingFn: (current: any, next: any) => {
      const currentStatus = STATUS_ACTIVE[current.original.Approved as keyof typeof STATUS_ACTIVE].toLowerCase()
      const nextStatus = STATUS_ACTIVE[next.original.Approved as keyof typeof STATUS_ACTIVE].toLowerCase()
      return currentStatus === nextStatus
        ? 0
        : currentStatus > nextStatus
          ? 1
          : -1
    }
  },
  {
    header: () => <span>Subscriber On</span>,
    accessorKey: 'CreatedOn',
    accessorFn: row => row.CreatedOn,
    id: 'CreatedOn',
    cell: info => new Date(info.getValue<string>()).toLocaleDateString('en-GB'),
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>CreatedBy</span>,
    accessorKey: 'CreatedBy',
    accessorFn: row => row.CreatedBy,
    id: 'CreatedBy',
    cell: info => {
      const profileId = info.getValue()
      return profileId
        ? <Link to={`/profile/${profileId}`}>Profile</Link>
        : 'N/A'
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    header: () => <span>Updated On</span>,
    accessorKey: 'UpdatedOn',
    accessorFn: row => row.UpdatedOn,
    id: 'UpdatedOn',
    cell: info => {
      const value = info.getValue()
      return value
        ? new Date(value as string).toLocaleDateString('en-GB')
        : 'N/A'
    },
    filterFn: dateFilter,
    sortingFn: 'datetime',
  },
  {
    header: () => <span>UpdatedBy</span>,
    accessorKey: 'UpdatedBy',
    accessorFn: row => row.UpdatedBy,
    id: 'UpdatedBy',
    cell: info => {
      const profileId = info.getValue()
      return profileId
        ? <Link to={`/profile/${profileId}`}>Profile</Link>
        : 'N/A'
    },
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    header: () => <span>Actions</span>,
    accessorKey: 'Id',
    accessorFn: row => row.Id,
    id: 'Actions',
    cell: info =>
      <Button
        className="btn-action border-0 bg-transparent m-auto d-block"
        data-id={info.getValue()}
        onClick={deleteSubscriber}
        data-toggle="tooltip"
        data-placement="bottom"
        title="Delete"
      >
        <Trash size={25} />
        {/* Delete */}
      </Button>,
    enableSorting: false,
    enableColumnFilter: false,
  },
]

export {
  subscribersTableColumns,
}