import * as React from "react";
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import { Link, useParams } from "react-router-dom";
import Organization from "../components/Forms/Organization";
import Individual from "../components/Forms/Individual";
import Observer from "../components/Forms/Observer";
import "./Register.css";

const Register: React.FC = () => {
  let params = useParams<{ type: string }>();
  const width = '18em'
  switch (params.type) {
    case "organization": return (<Organization />)
    case "individual": return (<Individual />)
    case "observer": return (<Observer />)
    default: return (
      <>
        <h1 className="display-6 mb-4 text-primary">
          Register for a new KFAS Learn account
        </h1>
        <p>An account gives you access to many benefits offered by KFAS.</p>
        <p>
          Please select the best option that describes what are you signing up
          for:
        </p>
        <div className="d-flex align-items-stretch justify-content-center justify-content-lg-start flex-wrap gap-4 mt-4">
          <Card
            className="kfas shadow"
            style={{ width }}
          >
            <Card.Header className="bg-transparent text-center text-primary fs-5">
              Individuals & HR Representatives
            </Card.Header>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">
                If your organization already has a organization account and
                you want to associate yourself with it, or if you're an
                individual who want to benefit from KFAS' training activities.
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer
              className="bg-white border-top-0 p-3"
            >
              <Link to="/register/individual">
                <Button>Register</Button>
              </Link>
            </Card.Footer>
          </Card>
          <Card
            className="kfas shadow"
            style={{ width }}
          >
            <Card.Header className="bg-transparent text-center text-primary fs-5">
              Organization Registration
            </Card.Header>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">
                If you're authorized by your organization to nominate
                individuals for training benefits with KFAS and you want to
                setup your corporate account with us for the first time.
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer
              className="bg-white border-top-0 p-3"
            >
              <Link to="/register/organization">
                <Button>Register Your Organization</Button>
              </Link>
            </Card.Footer>
          </Card>
          {/* <Card
            className="kfas"
            style={{ width }}
          >
            <Card.Header className='text-light text-center'>
              General Purpose
            </Card.Header>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted" as="h6">
                If you just need to be informed with our latest offerings and
                activities.
              </Card.Subtitle>
            </Card.Body>
            <Card.Footer>
              <Link to="/register/observer">
                <Button>Register as Observer</Button>
              </Link>
            </Card.Footer>
          </Card> */}
        </div>
      </>
    )
  }
}

export default Register;
